import { MODE } from '../../../utils/enums/enums';

export const stagingEnv = {
  mode: MODE.STAGING,
  api: process.env.REACT_APP_API_STAGING ?? '',
  debugIP: process.env.REACT_APP_REDUX_DEBUG_IP ?? false, // No cambiar
  paginationNumber: process.env.REACT_APP_PAGINATION
    ? parseInt(process.env.REACT_APP_PAGINATION)
    : 10,
};
