/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { TRANSPARENT } from '../../base/colors';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const select = () => ({
  MuiSelect: {
    styleOverrides: {
      select: {
        display: 'grid',
        alignItems: 'center',
        padding: `0 ${pxToRem(12)} !important`,

        '& .Mui-selected': {
          backgroundColor: TRANSPARENT.main,
        },
      },

      selectMenu: {
        background: 'none',
        height: 'none',
        minHeight: 'none',
        overflow: 'unset',
      },

      icon: {
        display: 'none',
      },
    },
  },
});

export default select;
