import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteSubjectService,
  getDetailSubjectService,
  getSubjectsService,
  saveSubjectService,
  updateSubjectService,
} from '../../../../services/subjects';

export const SubjectValues = {
  name: '',
};

const initialState = {
  loadingFormSubject: false,
  loadingSubjects: false,
  subjects: [],
};

const subjectSlice = createSlice({
  name: 'subjectSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormSubject = true;
      state.loadingSubjects = true;
    },
    all: (state, action) => {
      state.loadingFormSubject = false;
      state.loadingSubjects = false;
      state.subjects = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormSubject = false;
      state.loadingSubjects = false;
      const Items = state.subjects;
      Items.push(action.payload);
      state.subjects = Items;
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormSubject = false;
      state.loadingSubjects = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.subjects.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.subjects = items;
      }
    },
    successRemove: (state, action) => {
      state.subjects = state.subjects.filter((item) => item._id !== action.payload);
    },
    onSelectSubject: (state, action) => {
      state.selectedIdSubject = action.payload;
    },
    setDetailSubject: (state, action) => {
      state.detailSubject = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormSubject = false;
      state.loadingSubjects = false;
    },
    clearStateSubject: (state) => {
      state.loadingFormSubject = false;
      state.loadingSubjects = false;
      state.subjects = [];
      state.selectedIdSubject = null;
      state.detailSubject = null;
    },
  },
});

export const getSubjects = () => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await getSubjectsService();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveSubject = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveSubjectService(data);
      formikHelpers.resetForm();
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getDetailSubject = (id) => {
  return async (dispatch) => {
    try {
      const response = await getDetailSubjectService(id);
      dispatch(setDetailSubject(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updateSubject = (id, data, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateSubjectService(id, data);
      if (response.changes) {
        const responseDetail = await getDetailSubjectService(id);
        dispatch(successUpdate(responseDetail.data));
      }
      dispatch(successUpdate());
      handleClose();
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const deleteSubject = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteSubjectService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectSubject,
  setDetailSubject,
  successUpdate,
  successRemove,
  clearStateSubject,
} = subjectSlice.actions;

export default subjectSlice.reducer;
