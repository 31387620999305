import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Button, Typography } from '@mui/material';
import { dismissAlert } from '../../../redux/reducers/alert';
import { node } from 'prop-types';
import { useAppDispatch } from '../../../common/hooks';

export const SnackProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => {
    notistackRef.current?.closeSnackbar(key);
    dispatch(dismissAlert(key?.toString()));
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={4}
      dense
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{
        fontSize: 15,
        fontWeight: 700,
      }}
      action={(key) => (
        <Button onClick={() => onClickDismiss(key)}>
          <Typography
            variant='caption'
            style={{
              fontWeight: 300,
              fontSize: 12,
              color: '#FFF',
            }}
          >
            Cerrar
          </Typography>
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

SnackProvider.propTypes = {
  children: node,
};
