import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteUserService,
  saveUserService,
  serviceGetTeacher,
  serviceGetTeachers,
  updateUserService,
} from '../../../../services/users';

const initialState = {
  loadingFormTeacher: false,
  loadingTeachers: false,
  dataTeachers: [],
};

const teachersSlice = createSlice({
  name: 'teachersSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormTeacher = true;
      state.loadingTeachers = true;
    },
    all: (state, action) => {
      state.loadingFormTeacher = false;
      state.loadingTeachers = false;
      state.dataTeachers = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormTeacher = false;
      state.loadingTeachers = false;
      if (action.payload) {
        const Items = state.dataTeachers;
        Items.push(action.payload);
        state.dataTeachers = Items;
      }
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormTeacher = false;
      state.loadingTeachers = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataTeachers.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataTeachers = items;
      }
    },
    successRemove: (state, action) => {
      state.dataTeachers = state.dataTeachers.filter((item) => item._id !== action.payload);
    },
    onSelectTeacher: (state, action) => {
      state.selectedIdTeacher = action.payload;
    },
    setDetailTeacher: (state, action) => {
      state.detailTeacher = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormTeacher = false;
      state.loadingTeachers = false;
    },
    clearStateTeacher: (state) => {
      state.loadingFormTeacher = false;
      state.loadingTeachers = false;
      state.dataTeachers = [];
      state.selectedIdTeacher = null;
      state.detailTeacher = null;
    },
  },
});

export const getTeachers = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await serviceGetTeachers();
      dispatch(all(response));
      dispatch(successInsert());
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveTeacher = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveUserService(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedTeacher = (id, data, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateUserService(id, data);
      if (response.changes) {
        const responseDetail = await serviceGetTeacher(id);
        dispatch(successUpdate(responseDetail));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const deleteTeacher = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteUserService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getDetailTeacher = (id) => {
  return async (dispatch) => {
    try {
      const response = await serviceGetTeacher(id);
      dispatch(setDetailTeacher(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectTeacher,
  setDetailTeacher,
  successUpdate,
  successRemove,
  clearStateTeacher,
} = teachersSlice.actions;

export default teachersSlice.reducer;
