import { School } from './abc/schools/types';

export type address = {
  street: string;
  ext: string;
  int: string;
  colony: string;
  state: string;
  municipality: string;
  zipCode: string;
};

export type gender = 'female' | 'male' | 'other' | null;

export enum GENDER {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other'
}

export type Role = 'Admin' | 'Teacher' | 'Student' | 'Parent';

export enum ROLE_PROFILE {
  ADMIN = 'Admin',
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  PARENT = 'Parent',
}

export type payloadUserGeneral = {
  curp: string;
  name: string;
  lastname: string;
  birthday: string | null;
  age: string;
  gender: gender;
  address: address;
  role?: Role;
};

export type userDataGeneral = {
  _id: string;
  email: string;
  name: string;
  lastname: string;
  birthday: string;
  age: string;
  phone: string;
  curp: string;
  gender: gender;
  role: string;
  idSchool: string;
  address: address;
  created_at: string;
  updated_at: string;
  username: string;
  school: School;
};
