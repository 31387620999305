import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteGradeService,
  getDetailGradeService,
  getGradesService,
  saveGradeService,
  updateGradeService,
} from '../../../../services/grades';

export const gradeValues = {
  grade: '',
  idGroup: '',
};

const initialState = {
  loadingFormGrade: false,
  loadingGrades: false,
  grades: [],
};

const gradeSlice = createSlice({
  name: 'gradeSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormGrade = true;
      state.loadingGrades = true;
    },
    all: (state, action) => {
      state.loadingFormGrade = false;
      state.loadingGrades = false;
      state.grades = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormGrade = false;
      state.loadingGrades = false;
      const Items = state.grades;
      Items.push(action.payload);
      state.grades = Items;
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormGrade = false;
      state.loadingGrades = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.grades.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.grades = items;
      }
    },
    successRemove: (state, action) => {
      state.grades = state.grades.filter((item) => item._id !== action.payload);
    },
    onSelectGrade: (state, action) => {
      state.selectedIdGrade = action.payload;
    },
    setDetailGrade: (state, action) => {
      state.detailGrade = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormGrade = false;
      state.loadingGrades = false;
    },
    clearStateGrade: (state) => {
      state.loadingFormGrade = false;
      state.loadingGrades = false;
      state.grades = [];
      state.selectedIdGrade = null;
      state.detailGrade = null;
    },
  },
});

export const getGrades = () => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await getGradesService();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveGrade = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveGradeService(data);
      formikHelpers.resetForm();
      dispatch(createAlert({ message: 'Se agrego el grado.', status: 'success' }));
      dispatch(successInsert(response.data));
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getDetailGrade = (id) => {
  return async (dispatch) => {
    try {
      const response = await getDetailGradeService(id);
      dispatch(setDetailGrade(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedGrade = (id, data, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateGradeService(id, data);
      if (response.changes) {
        const responseDetailPeriod = await getDetailGradeService(id);
        dispatch(successUpdate(responseDetailPeriod.data));
      }
      dispatch(successUpdate());
      handleClose();
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const deleteGrade = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteGradeService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectGrade,
  setDetailGrade,
  successUpdate,
  successRemove,
  clearStateGrade,
} = gradeSlice.actions;

export default gradeSlice.reducer;
