import { lazy } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ImageIconsLibrary from '../components/atoms/ImageIcons';
import { ROLE_PROFILE, TYPE_ICONS, TYPE_IMAGE_ICONS } from '../utils/enums/enums';
import IconsLibrary from '../components/atoms/Icons';
import { Navigate } from 'react-router-dom';

const LoginPage = lazy(() => import('../views/landing'));
const SignInPage = lazy(() => import('../views/auth/signIn'));
const SignUpPage = lazy(() => import('../views/auth/signUp'));
const HomePage = lazy(() => import('../views/home'));
const PeriodsPage = lazy(() => import('../views/abc/periods'));
const GroupsPage = lazy(() => import('../views/abc/groups'));
const GradesPage = lazy(() => import('../views/abc/grades'));
const SubjectsPage = lazy(() => import('../views/abc/subjects'));
const AdminsPage = lazy(() => import('../views/abc/admin'));
const TeachersPage = lazy(() => import('../views/abc/teachers'));
const ParentsPage = lazy(() => import('../views/abc/parents'));
const StudentsPage = lazy(() => import('../views/abc/students'));
const CoursesPage = lazy(() => import('../views/courses'));
const ActivitiesPage = lazy(() => import('../views/activities/Activities'));
const DeliverablesPage = lazy(() => import('../views/deliverables'));
const AdvertisementPage = lazy(() => import('../views/advertisements'));
const AssistencePage = lazy(() => import('../views/assistance'));
const ReportCardsPage = lazy(() => import('../views/reportCards'));
const EvaluationsView = lazy(() => import('../views/evaluations'));
const ProfilePage = lazy(() => import('../views/account/profile'));
const ChangePasswordPage = lazy(() => import('../views/account/changePassword'));
const EditSchoolPage = lazy(() => import('../views/account/editSchool'));
const SettingsPage = lazy(() => import('../views/account/settings'));
const ActivatePage = lazy(() => import('../views/verify/activate'));
const ActivateErrorPage = lazy(() => import('../views/verify/error'));
const NotificationsPage = lazy(() => import('../views/notifications'));

export const ListMenuPublic = [
  {
    id: uuidv4(),
    path: '/',
    element: <Navigate to='/landing' />,
  },
  {
    id: uuidv4(),
    path: 'landing',
    element: <LoginPage />,
  },
  {
    id: uuidv4(),
    path: 'login',
    element: <SignInPage />,
  },
  {
    id: uuidv4(),
    path: 'register',
    element: <SignUpPage />,
  },
  {
    id: uuidv4(),
    path: 'activate',
    element: <ActivatePage />,
  },
  {
    id: uuidv4(),
    path: 'activate/error',
    element: <ActivateErrorPage />,
  },
];

export const ListMenuPrivate = (t) => [
  {
    id: uuidv4(),
    title: t('dashboard.title'),
    path: 'home',
    route: '/dashboard/home',
    element: <HomePage />,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.HOME_ICON} width='22px' />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    title: 'abc',
    path: 'dashboard/abc',
    route: '/dashboard/abc',
    isVisibleSidebar: true,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.ABC_ICON} width='22px' />,
    children: [
      {
        id: uuidv4(),
        title: t('periods.title'),
        path: 'abc/periods',
        route: '/dashboard/abc/periods',
        element: <PeriodsPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('groups.title'),
        path: 'abc/groups',
        route: '/dashboard/abc/groups',
        element: <GroupsPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('grades.title'),
        route: '/dashboard/abc/grades',
        path: 'abc/grades',
        element: <GradesPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('subjects.title'),
        route: '/dashboard/abc/subjects',
        path: 'abc/subjects',
        element: <SubjectsPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('administrators.title'),
        route: '/dashboard/abc/admins',
        path: 'abc/admins',
        element: <AdminsPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('teachers.title'),
        route: '/dashboard/abc/teachers',
        path: 'abc/teachers',
        element: <TeachersPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('parents.title'),
        route: '/dashboard/abc/parents',
        path: 'abc/parents',
        element: <ParentsPage />,
        isVisibleSidebar: true,
      },
      {
        id: uuidv4(),
        title: t('students.title'),
        route: '/dashboard/abc/students',
        path: 'abc/students',
        element: <StudentsPage />,
        isVisibleSidebar: true,
      },
    ],
  },
  {
    id: uuidv4(),
    title: t('courses.title'),
    route: '/dashboard/courses',
    path: 'courses',
    element: <CoursesPage />,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.COURSES_ICON} width='22px' />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    title: t('advertisement.title'),
    route: '/dashboard/advertisements',
    path: 'advertisements',
    element: <AdvertisementPage />,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.ADVERTISEMENT_ICON} width='22px' />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    title: t('assistence.title'),
    route: '/dashboard/assistences',
    path: 'assistences',
    element: <AssistencePage />,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.ASSISTENCE_ICON} width='22px' />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    title: t('report-cards.title'),
    route: '/dashboard/report-cards',
    path: 'report-cards',
    element: <ReportCardsPage />,
    icon: <ImageIconsLibrary name={TYPE_IMAGE_ICONS.REPORT_CARD_ICON} width='22px' />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    title: t('activities.title'),
    route: '/dashboard/activities',
    path: 'activities',
    element: <ActivitiesPage />,
    icon: <IconsLibrary name={TYPE_ICONS.BOOK_ICON} size={20} />,
    isVisibleSidebar: true,
  },
  {
    id: uuidv4(),
    path: 'activities/course/:idCourse',
    element: <ActivitiesPage />,
  },
  {
    id: uuidv4(),
    path: 'activities/children/:idCourse',
    element: <ActivitiesPage />,
  },
  {
    id: uuidv4(),
    path: 'activities/deliverables',
    element: <DeliverablesPage />,
  },
  {
    id: uuidv4(),
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    id: uuidv4(),
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    id: uuidv4(),
    path: 'changePassword',
    element: <ChangePasswordPage />,
  },
  {
    id: uuidv4(),
    path: 'editSchool',
    element: <EditSchoolPage />,
  },
  {
    id: uuidv4(),
    path: 'notifications',
    element: <NotificationsPage />,
  },
  {
    id: uuidv4(),
    path: 'report-cards/evaluations',
    element: <EvaluationsView />,
  },
];

export const userRoutes = (t, role) => {
  const menu = ListMenuPrivate(t).filter((item) => item?.isVisibleSidebar);

  const filterMenu = {
    [ROLE_PROFILE.ADMIN]: menu.filter((menu) => menu.path !== 'activities'),
    [ROLE_PROFILE.TEACHER]: menu.filter((menu) => menu.path !== 'dashboard/abc'),
    [ROLE_PROFILE.STUDENT]: menu.filter(
      (menu) => menu.path !== 'dashboard/abc' && menu.path !== 'assistences',
    ),
    [ROLE_PROFILE.PARENT]: menu.filter(
      (menu) => menu.path !== 'dashboard/abc' && menu.path !== 'activities',
    ),
  };

  return filterMenu[role];
};
