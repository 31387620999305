export enum GENDER {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
}

export enum ROLE_PROFILE {
  ADMIN = 'Admin',
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  PARENT = 'Parent',
}

export enum MODE {
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'production',
}

export enum STATUS_DELIVERABLE {
  DRAFT = 'draft',
  DELIVERED = 'delivered',
  PENDING = 'pending',
  QUALIFIED = 'qualified',
  RETARTED = 'retarded',
}

export enum LEVEL {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  LOG = 'log',
}

export enum MODULE {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  LOG = 'log',
}

export enum STATUS_ALERT {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum MODE_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}
