import {
  FaHome as HomeIcon,
  FaSun as SunIcon,
  FaMoon as MoonIcon,
  FaRegCalendarMinus as PeriodsIcon,
  FaUserAlt as UserIcon,
  FaSearch as SearchIcon,
  FaCog as SettingsIcon,
  FaBell as BellIcon,
  FaClock as ClockIcon,
  FaBars as MenuIcon,
  FaArrowRight as ArrowRightIcon,
  FaArrowLeft as ArrowLeftIcon,
  FaArrowDown as ArrowDownIcon,
  FaArrowUp as ArrowUpIcon,
  FaUserGraduate as StudentsIcon,
  FaUsers as TeachersIcon,
  FaEnvelope as MailIcon,
  FaLock as LockIcon,
  FaPowerOff as PowerOffIcon,
  FaPencilAlt as PencilIcon,
  FaBookReader as ClassIcon,
  FaTable as TableIcon,
  FaClipboardList as EvaluationsIcon,
  FaPlus as AddIcon,
  FaBook as BookIcon,
  FaListUl as ListIcon,
  FaSpellCheck as CheckActivityIcon,
  FaWhatsappSquare as WhatsappIcon,
  FaPhoneSquareAlt as PhoneIcon,
} from 'react-icons/fa';
import {
  FcDownload as DownloadIcon,
  FcFullTrash as TrashIcon,
  FcFile as FileIcon,
} from 'react-icons/fc';
import {
  MdMenuOpen as MenuOpenIcon,
  MdOutlineClose as CloseIcon,
  MdSchool as SchoolIcon,
  MdDashboardCustomize as GradesIcon,
  MdRecentActors as GroupsIcon,
  MdAdminPanelSettings as AdminsIcon,
  MdSend as SendIcon,
  // MdReduceCapacity as StudentsIcon,
} from 'react-icons/md';
import { PropsIcons } from './types';

export const IconsLibrary = ({ name = 'HomeIcon', ...props }: PropsIcons) => {
  const Icons = {
    HomeIcon: <HomeIcon {...props} />,
    CloseIcon: <CloseIcon {...props} />,
    SunIcon: <SunIcon {...props} />,
    MoonIcon: <MoonIcon {...props} />,
    PeriodsIcon: <PeriodsIcon {...props} />,
    GradesIcon: <GradesIcon {...props} />,
    GroupsIcon: <GroupsIcon {...props} />,
    AdminsIcon: <AdminsIcon {...props} />,
    StudentsIcon: <StudentsIcon {...props} />,
    TeachersIcon: <TeachersIcon {...props} />,
    UserIcon: <UserIcon {...props} />,
    SearchIcon: <SearchIcon {...props} />,
    SettingsIcon: <SettingsIcon {...props} />,
    BellIcon: <BellIcon {...props} />,
    ClockIcon: <ClockIcon {...props} />,
    MenuIcon: <MenuIcon {...props} />,
    MenuOpenIcon: <MenuOpenIcon {...props} />,
    SchoolIcon: <SchoolIcon {...props} />,
    ArrowRightIcon: <ArrowRightIcon {...props} />,
    ArrowLeftIcon: <ArrowLeftIcon {...props} />,
    MailIcon: <MailIcon {...props} />,
    LockIcon: <LockIcon {...props} />,
    PowerOffIcon: <PowerOffIcon {...props} />,
    PencilIcon: <PencilIcon {...props} />,
    TrashIcon: <TrashIcon {...props} />,
    ClassIcon: <ClassIcon {...props} />,
    TableIcon: <TableIcon {...props} />,
    EvaluationsIcon: <EvaluationsIcon {...props} />,
    AddIcon: <AddIcon {...props} />,
    BookIcon: <BookIcon {...props} />,
    SendIcon: <SendIcon {...props} />,
    ListIcon: <ListIcon {...props} />,
    CheckActivityIcon: <CheckActivityIcon {...props} />,
    FileIcon: <FileIcon {...props} />,
    DownloadIcon: <DownloadIcon {...props} />,
    ArrowDownIcon: <ArrowDownIcon {...props} />,
    ArrowUpIcon: <ArrowUpIcon {...props} />,
    WhatsappIcon: <WhatsappIcon {...props} />,
    PhoneIcon: <PhoneIcon {...props} />,
  };
  return Icons[name] || <HomeIcon {...props} />;
};
