import { MODE } from '../../../utils/enums/enums';

export const prodEnv = {
  mode: MODE.PROD,
  api: process.env.REACT_APP_API_PROD ?? '',
  debugIP: false, // No cambiar
  paginationNumber: process.env.REACT_APP_PAGINATION
    ? parseInt(process.env.REACT_APP_PAGINATION)
    : 10,
};
