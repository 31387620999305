import { createSlice } from '@reduxjs/toolkit';
import { clearFormData } from '../../../utils';
import { createAlert } from '../alert';
import { servicesDeliverables } from '../../../services/deliverables';

const initialState = {
  loadingDeliverables: true,
  dataDeliverables: [],
  selectedDeliverableId: null,
  activeDeliverable: null,
};

const deliverablesSlice = createSlice({
  name: 'deliverablesSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingDeliverables = true;
    },
    all: (state, action) => {
      state.loadingDeliverables = false;
      state.dataDeliverables = action.payload;
    },
    failure: (state) => {
      state.loadingDeliverables = false;
    },
    updateSuccess: (state, action) => {
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataDeliverables.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataDeliverables = items;
      }
    },
    setSelectDeliverId: (state, action) => {
      state.selectedDeliverableId = action.payload;
    },
    setActiveDeliverable: (state, action) => {
      state.activeDeliverable = action.payload;
    },
    setCommentDeliverable: (state, action) => {
      if (state.activeDeliverable) {
        const newComment = action.payload;
        state.activeDeliverable = {
          ...state.activeDeliverable,
          idComments: [...state.activeDeliverable.idComments, newComment],
        };
      }
    },
    removeDocument: (state, action) => {
      if (state.activeDeliverable) {
        const idAttachment = action.payload;
        state.activeDeliverable = {
          ...state.activeDeliverable,
          idAttachments: [
            ...state.activeDeliverable.idAttachments.filter(
              (attachment) => attachment._id !== idAttachment,
            ),
          ],
        };
      }
    },
    clearStateDeliverable: (state) => {
      state.loadingDeliverables = true;
      state.dataDeliverables = [];
      state.selectedDeliverableId = null;
      state.activeDeliverable = null;
    },
  },
});

/** Dispara para obtener todos los entregables. */
export const getDeliverables = () => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await servicesDeliverables().showDeliverables();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};
/** Dispara para obtener todos los entregables por actividad. */
export const getActivityDeliverables = (idActivity) => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await servicesDeliverables().showDeliverableActivity(idActivity);
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};
/** Dispara para obtener el entregable por el id. */
export const getDeliverable = (idDeliverable) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().showDeliverable(idDeliverable);
      dispatch(setActiveDeliverable(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};
/** Dispara para subir un entregable desde el perfil del alumno. */
export const uploadDeliverable = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().createDeliverable(data);

      dispatch(createAlert({ message: response.message, status: 'success' }));
      formikHelpers.resetForm();
      clearFormData(data);
      handleClose();
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};
/** Dispara para . */
export const getDeliverableActivity = (idActivity) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().showDeliverableActivity(idActivity);
      const { data } = response;
      const deliverable = data.length > 0 ? data[0] : null;
      dispatch(setActiveDeliverable(deliverable));
    } catch (e) {
      dispatch(failure());
    }
  };
};
/** Dispara para enviar comentarios en el entregable con el perfil del profesor, alumno y puede. */
export const sendCommentDeliverable = (idDeliverable, data, formikHelpers) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().sendCommentDeliverable(idDeliverable, data);
      dispatch(createAlert({ message: 'Se ha enviado el comentario', status: 'success' }));
      dispatch(setCommentDeliverable(response.changes));
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
    } catch (error) {
      dispatch(failure());
    }
  };
};
/** Dispara para actualizar el entregable desde el perfil del profesor. */
export const updateDeliverable = (idDeliverable, data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().updateDeliverable(idDeliverable, data);
      response.name === 'update' && response.changes
        ? await loadDeliverableUpdate()
        : dispatch(updateSuccess());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      formikHelpers.resetForm();
      clearFormData(data);
      handleClose && handleClose();
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
    async function loadDeliverableUpdate() {
      const response = await servicesDeliverables().showDeliverable(idDeliverable);
      dispatch(updateSuccess(response.data));
    }
  };
};

export const removeDocumentDeliverable = (idDeliverable, data) => {
  return async (dispatch) => {
    try {
      const response = await servicesDeliverables().deleteDocumentsDeliverable(idDeliverable, data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(removeDocument(data.idAttachment));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  setSelectDeliverId,
  setActiveDeliverable,
  setCommentDeliverable,
  updateSuccess,
  removeDocument,
  clearStateDeliverable,
} = deliverablesSlice.actions;

export default deliverablesSlice.reducer;
