import { API } from '../config';
import { mapCourseStudents } from '../utils';

async function fetchCourseStudents(params) {
  const response = API.get('users/getStudents', { params });
  const responseBeforeCourseStudents = (await response).data;
  const responseCourseStudents = {
    data: responseBeforeCourseStudents.data.map(mapCourseStudents),
  };
  return responseCourseStudents;
}

const createCourse = async (data) => {
  const res = await API.post('courses/create', data);
  return await res.data;
};

const updateCourse = async (id, data) => {
  const res = await API.put(`courses/update/${id}`, data);
  return await res.data;
};

const deleteCourse = async (id) => {
  const res = await API.delete(`courses/delete/${id}`);
  return await res.data;
};

const showCourses = async () => {
  const res = await API.get('courses');
  return await res.data;
};

const showCourse = async (id) => {
  const res = await API.get(`courses/show/${id}`);
  return await res.data;
};

const showCourseChildren = async (idStudent) => {
  const res = await API.get(`courses/children/${idStudent}`);
  return await res.data;
};

export function servicesCourses() {
  return {
    fetchCourseStudents,
    createCourse,
    updateCourse,
    deleteCourse,
    showCourses,
    showCourse,
    showCourseChildren,
  };
}
