import { prodEnv } from './prod';
import { stagingEnv } from './staging';
import { devEnv } from './dev';
import { logging } from '../../utils/logger';
import { MODE, MODULE } from '../../utils/enums/enums';

const { REACT_APP_MODE, REACT_APP_API_DEV, REACT_APP_API_STAGING } = process.env;

function convertAppMode(appMode) {
  if (REACT_APP_MODE) {
    if (REACT_APP_MODE === MODE.STAGING) {
      appMode = MODE.STAGING;
    } else if (REACT_APP_MODE === MODE.PROD) {
      appMode = MODE.PROD;
    }
  }
  return appMode;
}

const handler = () => {
  const logger = logging.getLogger(MODULE.INFO);
  let appMode = MODE.DEV;

  appMode = convertAppMode(appMode);

  if (REACT_APP_MODE === MODE.DEV || REACT_APP_MODE === MODE.STAGING) {
    logger.info(`Environment [${REACT_APP_MODE}]`);
    logger.info(`Environment [${REACT_APP_API_DEV}]`);
    logger.info(`Environment [${REACT_APP_API_STAGING}]`);
  }

  const modeApp = {
    [MODE.DEV]: devEnv,
    [MODE.STAGING]: stagingEnv,
    [MODE.PROD]: prodEnv,
  };

  return modeApp[appMode];
};
const env = handler();
export { env };
