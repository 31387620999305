import { API } from '../config';

export const saveGroupService = async (data) => {
  const res = await API.post('groups/add', data);
  return await res.data;
};

export const updateGroupService = async (id, data) => {
  const res = await API.put(`groups/edit/${id}`, data);
  return await res.data;
};

export const deleteGroupService = async (id) => {
  const res = await API.delete(`groups/delete/${id}`);
  return await res.data;
};

export const getGroupsService = async () => {
  const res = await API.get('groups/list');
  return await res.data;
};

export const getDetailGroupService = async (id) => {
  const res = await API.get(`groups/detail/${id}`);
  return await res.data;
};
