import { API } from '../config';

export const updateSchoolService = async (id, data) => {
  const res = await API.put(`schools/edit/${id}`, data);
  return await res.data;
};

export const getDetailschoolService = async (id) => {
  const res = await API.get(`schools/detail/${id}`);
  return await res.data;
};
