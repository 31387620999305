import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteGroupService,
  getDetailGroupService,
  getGroupsService,
  saveGroupService,
  updateGroupService,
} from '../../../../services/groups';

export const groupValues = {
  group: '',
};

const initialState = {
  loadingFormGroup: false,
  loadingGroups: false,
  groups: [],
};

export const groupSlice = createSlice({
  name: 'groupSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormGroup = true;
      state.loadingGroups = true;
    },
    all: (state, action) => {
      state.loadingFormGroup = false;
      state.loadingGroups = false;
      state.groups = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormGroup = false;
      state.loadingGroups = false;
      const groupsItems = state.groups;
      groupsItems.push(action.payload);
      state.groups = groupsItems;
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormGroup = false;
      state.loadingGroups = false;
      if (action.payload) {
        const updatedGroup = action.payload;
        const groups = state.groups.map((group) =>
          group._id === updatedGroup._id ? updatedGroup : group,
        );
        state.groups = groups;
      }
    },
    successRemove: (state, action) => {
      state.groups = state.groups.filter((item) => item._id !== action.payload);
    },
    onSelectGroup: (state, action) => {
      state.selectedIdGroup = action.payload;
    },
    setDetailGroup: (state, action) => {
      state.detailGroup = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormGroup = false;
      state.loadingGroups = false;
    },
    clearStateGroup: (state) => {
      state.loadingFormGroup = false;
      state.loadingGroups = false;
      state.groups = [];
      state.selectedIdGroup = null;
      state.detailGroup = null;
    },
  },
});

export const getGroups = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await getGroupsService();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveGroup = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveGroupService(data);
      formikHelpers.resetForm();
      dispatch(createAlert({ message: 'Se ha agregado el grupo', status: 'success' }));
      dispatch(successInsert(response.data));
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getDetailGroup = (id) => {
  return async (dispatch) => {
    try {
      const response = await getDetailGroupService(id);
      dispatch(setDetailGroup(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedGroup = (id, data, handleClose, formikHelpers) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateGroupService(id, data);
      if (response.changes) {
        const responseDetailPeriod = await getDetailGroupService(id);
        dispatch(successUpdate(responseDetailPeriod.data));
      }
      dispatch(successUpdate());
      handleClose();
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const deleteGroup = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteGroupService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectGroup,
  setDetailGroup,
  successUpdate,
  successRemove,
  clearStateGroup,
} = groupSlice.actions;

export default groupSlice.reducer;
