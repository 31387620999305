/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { MODE_THEME } from '../../enums/enums';
import { alpha } from '@mui/material';

/**
 * The base colors for the Soft UI Dashboard React.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard React using thie file.
 */

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

export const PRIMARY = {
  lighter: '#dfb6b6',
  light: '#d25353',
  main: '#cc0000',
  dark: '#dd0202',
  darker: '#7d0000',
  contrastText: '#fff',
};

export const DARK = {
  main: '#344767',
  focus: '#344767',
};

export const TRANSPARENT = {
  main: 'transparent',
};

export const WHITE = {
  main: '#ffffff',
  focus: '#ffffff',
};

export const LIGHT = {
  main: '#e9ecef',
  focus: '#e9ecef',
};

export const BLACK = {
  light: '#141414',
  main: '#000000',
  focus: '#000000',
};

export const GRADIENTS = {
  primary: {
    main: '#7928ca',
    state: '#ff0080',
  },

  secondary: {
    main: '#627594',
    state: '#a8b8d8',
  },

  info: {
    main: '#2152ff',
    state: '#21d4fd',
  },

  success: {
    main: '#17ad37',
    state: '#98ec2d',
  },

  warning: {
    main: '#f53939',
    state: '#fbcf33',
  },

  error: {
    main: '#ea0606',
    state: '#ff667c',
  },

  light: {
    main: '#ced4da',
    state: '#ebeff4',
  },

  dark: {
    main: '#141727',
    state: '#3a416f',
  },
};

export const SLIDERCOLORS = {
  thumb: { borderColor: '#d9d9d9' },
};

export const SECONDARY = {
  main: '#8392ab',
  focus: '#96a2b8',
};

export const INFO = {
  main: '#17c1e8',
  focus: '#3acaeb',
};

export const INPUT_COLORS = {
  borderColor: { main: '#d2d6da', focus: '#35d1f5' },
  boxShadow: '#81e3f9',
  error: '#fd5c70',
  success: '#66d432',
};

export const BACKGROUND_COLOR = {
  default: '#f8f9fa',
  dark: '#000'
};

const colors = (mode) => {
  const TEXT = {
    primary: mode === MODE_THEME.DARK ? '#FFF' : GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  };

  return {
    // background: BACKGROUND_COLOR,

    text: TEXT,

    transparent: TRANSPARENT,

    white: WHITE,

    black: BLACK,

    primary: PRIMARY,

    secondary: SECONDARY,

    info: INFO,

    success: {
      main: '#82d616',
      focus: '#95dc39',
    },

    warning: {
      main: '#fbcf33',
      focus: '#fcd652',
    },

    error: {
      main: '#ea0606',
      focus: '#c70505',
    },

    light: LIGHT,

    dark: DARK,

    grey: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },

    gradients: GRADIENTS,

    socialMediaColors: {
      facebook: {
        main: '#3b5998',
        dark: '#344e86',
      },

      twitter: {
        main: '#55acee',
        dark: '#3ea1ec',
      },

      instagram: {
        main: '#125688',
        dark: '#0e456d',
      },

      linkedin: {
        main: '#0077b5',
        dark: '#00669c',
      },

      pinterest: {
        main: '#cc2127',
        dark: '#b21d22',
      },

      youtube: {
        main: '#e52d27',
        dark: '#d41f1a',
      },

      vimeo: {
        main: '#1ab7ea',
        dark: '#13a3d2',
      },

      slack: {
        main: '#3aaf85',
        dark: '#329874',
      },

      dribbble: {
        main: '#ea4c89',
        dark: '#e73177',
      },

      github: {
        main: '#24292e',
        dark: '#171a1d',
      },

      reddit: {
        main: '#ff4500',
        dark: '#e03d00',
      },

      tumblr: {
        main: '#35465c',
        dark: '#2a3749',
      },
    },

    alertColors: {
      primary: {
        main: '#7928ca',
        state: '#d6006c',
        border: '#efb6e2',
      },

      secondary: {
        main: '#627594',
        state: '#8ca1cb',
        border: '#dadee6',
      },

      info: {
        main: '#2152ff',
        state: '#02c6f3',
        border: '#b9ecf8',
      },

      success: {
        main: '#17ad37',
        state: '#84dc14',
        border: '#daf3b9',
      },

      warning: {
        main: '#f53939',
        state: '#fac60b',
        border: '#fef1c2',
      },

      error: {
        main: '#ea0606',
        state: '#ff3d59',
        border: '#f9b4b4',
      },

      light: {
        main: '#ced4da',
        state: '#d1dae6',
        border: '#f8f9fa',
      },

      dark: {
        main: '#141727',
        state: '#2c3154',
        border: '#c2c8d1',
      },
    },

    badgeColors: {
      primary: {
        background: '#f883dd',
        text: '#a3017e',
      },

      secondary: {
        background: '#e4e8ed',
        text: '#5974a2',
      },

      info: {
        background: '#abe9f7',
        text: '#08a1c4',
      },

      success: {
        background: '#cdf59b',
        text: '#67b108',
      },

      warning: {
        background: '#fef5d3',
        text: '#fbc400',
      },

      error: {
        background: '#fc9797',
        text: '#bd0000',
      },

      light: {
        background: '#ffffff',
        text: '#c7d3de',
      },

      dark: {
        background: '#8097bf',
        text: '#1e2e4a',
      },
    },

    inputColors: INPUT_COLORS,

    sliderColors: SLIDERCOLORS,

    circleSliderColors: {
      background: '#d3d3d3',
    },

    tabs: {
      indicator: { boxShadow: '#ddd' },
    },
  };
};

export default colors;
