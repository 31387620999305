import { TFunction } from 'i18next';

export const optionsGeneral = {
    pagination: false,
    selectableRows: undefined,
};

export const textLabels = (t: TFunction<'translation', undefined>) => {
  const optionstextLabels = {
    body: {
      noMatch: t('optionstextLabels.noMatch'),
    },
    toolbar: {
      search: t('optionstextLabels.search'),
      downloadCsv: t('optionstextLabels.downloadCsv'),
      print: t('optionstextLabels.print'),
      viewColumns: t('optionstextLabels.viewColumns'),
      filterTable: t('optionstextLabels.filterTable'),
    },
    viewColumns: {
      title: t('optionstextLabels.viewColumns'),
      titleAria: t('optionstextLabels.titleAria'),
    },
    filter: {
      title: t('optionstextLabels.filterTitle'),
      reset: t('optionstextLabels.reset'),
      all: t('optionstextLabels.all'),
    },
  };
  return optionstextLabels;
};

type TypesTextLabels = {
  bodyText: string;
};

export const optionsTextLabels = ({ bodyText }: TypesTextLabels) => {
  const optionstextLabels = {
    body: {
      noMatch: bodyText,
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Descargar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Columnas',
      filterTable: 'Filtrar',
    },
    viewColumns: {
      title: 'Ver Columnas',
      titleAria: 'Mostrar/Ocultar columnas de tabla',
    },
    filter: {
      title: 'Filtrar',
      reset: 'Resetear',
      all: 'Todos',
    },
  };
  return optionstextLabels;
};
