/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { DARK, WHITE } from '../../base/colors';
import borders from '../../base/borders';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';
import boxShadow from '../../functions/boxShadow';

const { borderWidth, borderColor } = borders;

const stepIcon = () => ({
  MuiStepIcon: {
    styleOverrides: {
      root: {
        background: WHITE.main,
        fill: WHITE.main,
        stroke: WHITE.main,
        strokeWidth: pxToRem(10),
        width: pxToRem(13),
        height: pxToRem(13),
        border: `${borderWidth[2]} solid ${borderColor}`,
        borderRadius: '50%',
        zIndex: 99,
        transition: 'all 200ms linear',

        '&.Mui-active': {
          background: DARK.main,
          fill: DARK.main,
          stroke: DARK.main,
          borderColor: DARK.main,
          boxShadow: boxShadow([0, 0], [0, 2], DARK.main, 1),
        },

        '&.Mui-completed': {
          background: DARK.main,
          fill: DARK.main,
          stroke: DARK.main,
          borderColor: DARK.main,
          boxShadow: boxShadow([0, 0], [0, 2], DARK.main, 1),
        },
      },
    },
  },
});

export default stepIcon;
