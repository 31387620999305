import { API } from '../config';
import { transformChildren } from './users';

function transformDeliverable({ idStudent, ...rest }) {
  return {
    ...rest,
    idStudent: transformChildren(idStudent),
  };
}

const createDeliverable = async (data) => {
  const res = await API.post('deliverables/create', data);
  return await res.data;
};

const sendCommentDeliverable = async (idDeliverable, data) => {
  const res = await API.put(`deliverables/comment/${idDeliverable}`, data);
  return await res.data;
};

const showDeliverables = async () => {
  const res = await API.get('deliverables');
  const { data, ...rest } = await res.data;
  const result = data.map(transformDeliverable);

  return {
    ...rest,
    data: result,
  };
};

/**
 * Esta funcion consulta el detalle del entregable
 */
const showDeliverable = async (idDeliverable) => {
  const res = await API.get(`deliverables/show/${idDeliverable}`);
  const { data, ...rest } = await res.data;

  return {
    ...rest,
    data: transformDeliverable(data),
  };
};
/**
 * Esta funcion hace una actualizacion al entregable pasando como parametro el id del entregable
 * y la data del entregable
 * @param {string} idDeliverable - string
 * @param {TypeFormDeliverable} data - TypeFormDeliverable
 * @returns La respuesta del API
 */
const updateDeliverable = async (idDeliverable, data) => {
  const res = await API.put(`deliverables/update/${idDeliverable}`, data);
  return await res.data;
};
/**
 * Esta funcion devuelve todos los entregables por actividad
 * @param {string} idActivity - string
 * @returns La respuesta del API
 */
const showDeliverableActivity = async (idActivity) => {
  const res = await API.get(`deliverables/showActivity/${idActivity}`);
  const { data, ...rest } = await res.data;
  const result = data.map(transformDeliverable);

  return {
    ...rest,
    data: result,
  };
};

const deleteDocumentsDeliverable = async (idDeliverable, data) => {
  const res = await API.put(`deliverables/document/${idDeliverable}`, data);
  return await res.data;
};

export const servicesDeliverables = () => {
  return {
    createDeliverable,
    showDeliverableActivity,
    sendCommentDeliverable,
    showDeliverables,
    showDeliverable,
    updateDeliverable,
    deleteDocumentsDeliverable,
  };
};
