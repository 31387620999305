import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteUserService,
  serviceGetAdministrators,
  serviceGetAdministrator,
  saveUserService,
  updateUserService,
} from '../../../../services/users';

const initialState = {
  loadingFormAdmin: false,
  loadingAdmins: false,
  dataAdmins: [],
};

const adminsSlice = createSlice({
  name: 'adminsSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormAdmin = true;
      state.loadingAdmins = true;
    },
    all: (state, action) => {
      state.loadingFormAdmin = false;
      state.loadingAdmins = false;
      state.dataAdmins = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormAdmin = false;
      state.loadingAdmins = false;
      if (action.payload) {
        const Items = state.dataAdmins;
        Items.push(action.payload);
        state.dataAdmins = Items;
      }
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormAdmin = false;
      state.loadingAdmins = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataAdmins.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataAdmins = items;
      }
    },
    successRemove: (state, action) => {
      state.dataAdmins = state.dataAdmins.filter((item) => item._id !== action.payload);
    },
    onSelectAdmin: (state, action) => {
      state.selectedIdAdmin = action.payload;
    },
    setDetailAdmin: (state, action) => {
      state.detailAdmin = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormAdmin = false;
      state.loadingAdmins = false;
    },
    clearStateAdmin: (state) => {
      state.loadingFormAdmin = false;
      state.loadingAdmins = false;
      state.dataAdmins = [];
      state.selectedIdAdmin = null;
      state.detailAdmin = null;
    },
  },
});

export const getAdmins = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await serviceGetAdministrators();
      dispatch(all(response));
      dispatch(successInsert());
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveAdmin = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveUserService(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedAdmin = (id, data, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateUserService(id, data);
      if (response.changes) {
        const responseDetail = await serviceGetAdministrator(id);
        dispatch(successUpdate(responseDetail));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const deleteAdmin = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteUserService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getDetailAdmin = (id) => {
  return async (dispatch) => {
    try {
      const response = await serviceGetAdministrator(id);
      dispatch(setDetailAdmin(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectAdmin,
  setDetailAdmin,
  successUpdate,
  successRemove,
  clearStateAdmin,
} = adminsSlice.actions;

export default adminsSlice.reducer;
