/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { GREY, WHITE } from '../../base/colors';
import borders from '../../base/borders';
import boxShadows from '../../base/boxShadows';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = () => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        position: 'relative',
        backgroundColor: GREY[100],
        borderRadius: borderRadius.lg,
        minHeight: 'unset',
        padding: pxToRem(4),
      },

      flexContainer: {
        height: '100%',
        position: 'relative',
        zIndex: 10,
      },

      fixed: {
        overflow: 'unset !important',
        overflowX: 'unset !important',
      },

      vertical: {
        '& .MuiTabs-indicator': {
          width: '100%',
        },
      },

      indicator: {
        height: '100%',
        borderRadius: borderRadius.md,
        backgroundColor: WHITE.main,
        boxShadow: tabsBoxShadow.indicator,
        transition: 'all 500ms ease',
      },
    },
  },
});

export default tabs;
