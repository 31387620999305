import { API } from '../config';
const createActivity = async (data) => {
  const res = await API.post('activities/create', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return await res.data;
};
const updateActivity = async (id, data) => {
  const res = await API.put(`activities/update/${id}`, data);
  return await res.data;
};
const deleteActivity = async (id) => {
  const res = await API.delete(`activities/delete/${id}`);
  return await res.data;
};
const showActivities = async () => {
  const res = await API.get('activities');
  return await res.data;
};
const showActivity = async (id) => {
  const res = await API.get(`activities/show/${id}`);
  return await res.data;
};
const deleteDocumentActivity = async (id, data) => {
  const res = await API.put(`activities/document/${id}`, data);
  return await res.data;
};
const showActivitiesCourse = async (idCourse) => {
  const res = await API.get(`activities/course/${idCourse}`);
  return await res.data;
};

/**
 * Esta funcion retorna una promesa para consultar las actividades desde el grado del alumno.
 * @param {string} idGrade - string - Id del grado del alumno
 * @returns El array de la actividad relacionado del alumno.
 */
const showActivityChildren = async (idGrade) => {
  const res = await API.get(`activities/children/${idGrade}`);
  return await res.data;
};

export const servicesActivities = () => {
  return {
    createActivity,
    updateActivity,
    deleteActivity,
    showActivities,
    showActivity,
    deleteDocumentActivity,
    showActivitiesCourse,
    showActivityChildren,
  };
};
