import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deletePeriodService,
  getDetailPeriodService,
  getPeriodsService,
  savePeriodService,
  updatePeriodService,
} from '../../../../services/periods';

export const periodValues = {
  name: '',
  startDate: null,
  endDate: null,
};

const initialState = {
  loadingFormPeriod: false,
  loadingPeriods: false,
  periods: [],
};

export const periodsSlice = createSlice({
  name: 'periodsSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormPeriod = true;
      state.loadingPeriods = true;
    },
    all: (state, action) => {
      state.loadingFormPeriod = false;
      state.loadingPeriods = false;
      state.periods = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormPeriod = false;
      state.loadingPeriods = false;
      const Items = state.periods;
      Items.push(action.payload);
      state.periods = Items;
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormPeriod = false;
      state.loadingPeriods = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.periods.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.periods = items;
      }
    },
    successRemove: (state, action) => {
      state.periods = state.periods.filter((item) => item._id !== action.payload);
    },
    onSelectPeriod: (state, action) => {
      state.selectedIdPeriod = action.payload;
    },
    setDetailPeriod: (state, action) => {
      state.detailPeriod = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormPeriod = false;
      state.loadingPeriods = false;
    },
    clearStatePeriod: (state) => {
      state.loadingFormPeriod = false;
      state.loadingPeriods = false;
      state.error = false;
      state.periods = [];
      state.selectedIdPeriod = null;
      state.detailPeriod = null;
    },
  },
});

export const savePeriod = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await savePeriodService(data);
      formikHelpers.resetForm();
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      handleClose();
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedPeriod = (id, data, handleClose, formikHelpers) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updatePeriodService(id, data);
      if (response.changes) {
        const responseDetail = await getDetailPeriodService(id);
        dispatch(successUpdate(responseDetail.data));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      dispatch(setDetailPeriod());
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getPeriods = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await getPeriodsService();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const deletePeriod = (id) => {
  return async (dispatch) => {
    try {
      const response = await deletePeriodService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getDetailPeriod = (id) => {
  return async (dispatch) => {
    try {
      const response = await getDetailPeriodService(id);
      dispatch(setDetailPeriod(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectPeriod,
  setDetailPeriod,
  successUpdate,
  successRemove,
  clearStatePeriod,
} = periodsSlice.actions;

export default periodsSlice.reducer;
