/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { GREY } from '../base/colors';
import typography from '../base/typography';

const { size } = typography;

const breadcrumbs = () => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        lineHeight: 0,
      },

      separator: {
        fontSize: size.sm,
        color: GREY[600],
      },
    },
  },
});

export default breadcrumbs;
