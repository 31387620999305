import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { LOCALE, MODE_THEME } from '../../utils/enums/enums';
import { storage } from '../../utils';

const storageMode = storage.getItem('@theme') ?? null;

const initialStateAds = {
  advertisements: {
    isLoading: false,
    data: [],
  },
  advertisementId: null,
  advertisement: null,
  setAdvertisements: noop,
  setAdvertisementId: noop,
  setDetailAds: noop,
  pushItemAdvertisement: noop,
  removeItemAdvertisement: noop,
  updateItemAdvertisement: noop,
};

export const initialState = {
  isOpenSidebar: false,
  locale: LOCALE.ES,
  theme: storageMode === MODE_THEME.DARK ? MODE_THEME.DARK : MODE_THEME.LIGHT,
  fixedNavbar: true,
  transparentNavbar: true,
  transparentSidenav: false,
  miniSidenav: false,
  navbarType: 'static',
  onToggleSidebar: noop,
  setLocale: noop,
  changeTheme: noop,
  setTransparentNavbar: noop,
  setMiniSidenav: noop,
  setTransparentSidenav: noop,
  setFixedNavbar: noop,
  ...initialStateAds,
};

export const AppContext = createContext(initialState);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context;
};
