/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { DARK, TRANSPARENT, WHITE } from '../base/colors';

// Soft UI Dashboard React helper functions
import rgba from '../functions/rgba';
import pxToRem from '../functions/pxToRem';

const divider = () => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: TRANSPARENT.main,
        backgroundImage: `linear-gradient(to right, ${rgba(DARK.main, 0)}, ${rgba(
          DARK.main,
          0.5,
        )}, ${rgba(DARK.main, 0)}) !important`,
        height: pxToRem(1),
        margin: `${pxToRem(16)} 0`,
        borderBottom: 'none',
        opacity: 0.25,
      },

      vertical: {
        backgroundColor: TRANSPARENT.main,
        backgroundImage: `linear-gradient(to bottom, ${rgba(DARK.main, 0)}, ${rgba(
          DARK.main,
          0.5,
        )}, ${rgba(DARK.main, 0)}) !important`,
        width: pxToRem(1),
        height: '100%',
        margin: `0 ${pxToRem(16)}`,
        borderRight: 'none',
      },

      light: {
        backgroundColor: TRANSPARENT.main,
        backgroundImage: `linear-gradient(to right, ${rgba(WHITE.main, 0)}, ${rgba(
          WHITE.main,
          0.5,
        )}, ${rgba(WHITE.main, 0)}) !important`,

        '&.MuiDivider-vertical': {
          backgroundImage: `linear-gradient(to bottom, ${rgba(WHITE.main, 0)}, ${rgba(
            WHITE.main,
            0.5,
          )}, ${rgba(WHITE.main, 0)}) !important`,
        },
      },
    },
  },
});

export default divider;
