import { isArray } from 'lodash';

import {
  ActivityInterface,
  ActivityDTOInterface,
  CourseStudentsDTOInterface,
  NotificationDTOInterface,
  NotificationsInterface,
  CourseStudentsInterface,
  DeliverableDTOInterface,
  DeliverableInterface,
} from './types';
import { TYPE_ICONS } from './enums/enums';

/**
 * It takes a FormData object and deletes all of its keys
 * @param {FormData} data - FormData
 */
export function clearFormData(data: FormData) {
  data.forEach(function (_, key) {
    data.delete(key);
  });
}

/**
 * It takes a string and a number as arguments, and returns a string that is the original string
 * truncated to the number of characters specified by the number argument
 * @param {string} str - string - the string to be truncated
 * @param {number} n - number -&gt; the number of characters you want to display
 * @returns a string.
 */
export function truncateNameFile(str: string, n: number) {
  const stringName = str.split('.');
  const extesion = stringName[1];
  return str.length > n ? str.slice(0, n - 1) + '...' + extesion : str;
}

export function mapNotifications({
  _id,
  ...props
}: NotificationDTOInterface): NotificationsInterface {
  return {
    notificationId: _id,
    ...props,
  };
}

const mapActivities = ({ _id, deliverable, ...props }: ActivityDTOInterface): ActivityInterface => {
  const activity: ActivityInterface = {
    activityId: _id,
    deliverable: setDelivery(deliverable),
    ...props,
  };
  return activity;
};

function setDelivery(
  deliverable: DeliverableDTOInterface | undefined,
): DeliverableInterface | undefined {
  if (!deliverable) return undefined;
  const { _id, idComments, idDocuments, ...rest } = deliverable;
  return {
    deliverableId: _id,
    comments: idComments,
    documents: idDocuments,
    ...rest,
  };
}

export function mapCourseStudents({
  activities,
  ...props
}: CourseStudentsDTOInterface): CourseStudentsInterface {
  return {
    activities: activities.map(mapActivities),
    ...props,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function modifyByIndex<Type>(index: number, array: Type[] = [], item: any): Type[] {
  if (isArray(item)) return [...array.slice(0, index), ...item, ...array.slice(index + 1)];
  return [...array.slice(0, index), { ...item }, ...array.slice(index + 1)];
}

export function removeByIndex<Type>(index: number, array: Type[] = []): Type[] {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export const randomColor = Math.floor(Math.random() * 16777215).toString(16);

export const removeSpace = (str: string) => {
  return str.replace(/\s+/g, '');
};

export const convertToLowerCase = (str: string) => {
  return str.toLowerCase();
};

export const trimString = (str: string) => {
  return str.trim();
};

export const actionsBackdrop = ({
  txtBtnAdd,
  handleNewOpenModal,
}: {
  txtBtnAdd: string;
  handleNewOpenModal: () => void;
}) => [
  {
    icon: TYPE_ICONS.ADD_ICON,
    name: txtBtnAdd,
    onClick: handleNewOpenModal,
  },
];
