import { es } from 'date-fns/locale';
import { format, formatDistanceToNow, formatISO } from 'date-fns';
// ----------------------------------------------------------------------

export const currentDate = new Date();

export function formatDate(date: string | number | Date, formatString = 'dd MMMM yyyy') {
  return format(new Date(date), formatString, { locale: es });
}

export function formatDateISO(date: number | Date) {
  return formatISO(date);
}

export function formatDatetime(date: string | number | Date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function formatDatetimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formatToDate(date: string | number | Date) {
  return new Date(date);
}
