import { Suspense } from 'react';
import { Preloader, PreloaderCircular } from '../Preloader';
import { node } from 'prop-types';

export const SuspenseLayout = ({ children }) => (
  <Suspense fallback={<Preloader />}>{children}</Suspense>
);

export const SuspenseLoader = ({ children }) => (
  <Suspense fallback={<PreloaderCircular />}>{children}</Suspense>
);

SuspenseLayout.propTypes = {
  children: node.isRequired,
};

SuspenseLoader.propTypes = {
  children: node.isRequired,
};
