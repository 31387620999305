/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { DARK } from '../../base/colors';
import typography from '../../base/typography';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const { size, fontWeightBold } = typography;

const formControlLabel = () => ({
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        display: 'block',
        minHeight: pxToRem(24),
        marginBottom: pxToRem(2),
      },

      label: {
        display: 'inline-block',
        fontSize: size.sm,
        fontWeight: fontWeightBold,
        color: DARK.main,
        lineHeight: 1,
        transform: `translateY(${pxToRem(1)})`,
        marginLeft: pxToRem(4),

        '&.Mui-disabled': {
          color: DARK.main,
        },
      },
    },
  },
});

export default formControlLabel;
