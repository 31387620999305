import { Box, CircularProgress, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Spinner = styled('div')(
  ({ theme, width, height }) => `
  overflow: hidden;
  position: absolute;
  width: ${width}px;
  height: ${height}px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: ${theme.palette.primary.main};
  animation: spin 1500ms infinite linear;
  &::before{
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border: 3px solid transparent;
    border-top-color: ${theme.palette.primary.main};
    content: "";
    position: absolute;
    border-radius: 60%;
    animation: antispin 700ms infinite linear;
  }
  @keyframes spin { 100% {transform:rotate(360deg);} }
  @keyframes antispin {
    from {transform: rotate}
    to {transform: rotate(-360deg);}
  } 
`,
);

export const Preloader = () => {
  const theme = useTheme();
  return (
    <Box position='relative' display='block' height='100vh'>
      <Box
        position='absolute'
        display='flex'
        justifyContent='center'
        alignItems='center'
        top={0}
        left={0}
        bottom={0}
        right={0}
      >
        <Spinner theme={theme} width={230} height={230} />
      </Box>
    </Box>
  );
};

export const PreloaderCircular = () => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};
