import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { AppDispatch } from '../../redux/store';

/**
 * It returns the dispatch function from the Redux store, but with a type that's specific to the app
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const getAuthState = (state: RootState) => state.auth;

export const getAlertsState = (state: RootState) => state.alerts;

export const getProfileState = (state: RootState) => state.profileSlice;

export const getPeriodState = (state: RootState) => state.periodSlice;

export const getGroupState = (state: RootState) => state.groupSlice;

export const getGradeState = (state: RootState) => state.gradeSlice;

export const getSubjectState = (state: RootState) => state.subjectSlice;

export const getTeachersState = (state: RootState) => state.teachersSlice;

export const getStudentsState = (state: RootState) => state.studentsSlice;

export const getAdminsState = (state: RootState) => state.adminsSlice;

export const getParentsState = (state: RootState) => state.parentsSlice;

export const getCourseState = (state: RootState) => state.courseSlice;

export const getActivitiesState = (state: RootState) => state.activitiesSlice;

export const getDeliverablesState = (state: RootState) => state.deliverablesSlice;

export const getSchoolsState = (state: RootState) => state.schoolsSlice;
