export const ACTIONS = {
  SET_STUDENTS_BY_COURSE: 'SET_STUDENTS_BY_COURSE',
  SET_ASSISTANCE_ID: 'SET_ASSISTANCE_ID',
  SET_SELECTED_ASSISTANCE: 'SET_SELECTED_ASSISTANCE',
  SET_STUDENTS_ATTENDANCE: 'SET_STUDENTS_ATTENDANCES',
  SET_CHILDREN_SELECTED: 'SET_CHILDREN_SELECTED',
  SET_COURSE_SELECTED: 'SET_COURSE_SELECTED',
};

export const AssistancesContextReducer = (state, reducerPayload) => {
  const { type: actionType, payload } = reducerPayload;
  switch (actionType) {
    case ACTIONS.SET_STUDENTS_BY_COURSE: {
      const {
        students: { isLoading, data },
      } = payload;

      return {
        ...state,
        students: {
          isLoading,
          data: isLoading ? state.students.data : data,
        },
      };
    }
    case ACTIONS.SET_ASSISTANCE_ID: {
      const { assistanceId } = payload;

      return {
        ...state,
        assistanceId,
      };
    }
    case ACTIONS.SET_SELECTED_ASSISTANCE: {
      const { selectedAssistance } = payload;

      return {
        ...state,
        selectedAssistance,
      };
    }
    case ACTIONS.SET_STUDENTS_ATTENDANCE: {
      const {
        studentsAttendance: { isLoading, data },
      } = payload;

      return {
        ...state,
        studentsAttendance: {
          isLoading,
          data: isLoading ? state.studentsAttendance.data : data,
        },
      };
    }
    case ACTIONS.SET_CHILDREN_SELECTED: {
      const { childrenSelected } = payload;

      return {
        ...state,
        childrenSelected,
      };
    }
    case ACTIONS.SET_COURSE_SELECTED: {
      const { courseSelected } = payload;

      return {
        ...state,
        courseSelected,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
