import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../alert';
import { servicesCourses } from '../../../services/courses';

const initialState = {
  loadingCourses: false,
  dataCourses: [],
};

const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingCourses = true;
    },
    all: (state, action) => {
      state.loadingCourses = false;
      state.dataCourses = action.payload;
    },
    failure: (state) => {
      state.loadingCourses = false;
    },
    createSuccess: (state, action) => {
      if (action.payload) {
        const newItem = action.payload;
        const Items = state.dataCourses;
        Items.push(newItem);
        state.dataCourses = Items;
      }
    },
    updateSuccess: (state, action) => {
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataCourses.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataCourses = items;
      }
    },
    setDetailCourse: (state, action) => {
      state.detailCourse = action.payload;
      state.loadingCourses = false;
    },
    onSelectCourse: (state, action) => {
      state.idCourseSelected = action.payload;
    },
    removeSuccess: (state, action) => {
      state.dataCourses = state.dataCourses.filter((item) => item._id !== action.payload);
    },
    clearStateCourse: (state) => {
      state.loadingCourses = false;
      state.dataCourses = [];
      state.detailCourse = null;
      state.idCourseSelected = null;
    }
  },
});

export const getCourses = ({ idStudent, formikHelpers }) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      let response = {
        data: [],
        status: false,
      };
      idStudent && formikHelpers
        ? (response = await loadCoursesChildren(response, idStudent, formikHelpers))
        : (response = await servicesCourses().showCourses());
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const fetchCourses = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await servicesCourses().showCourses();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const createCourse = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    try {
      const response = await servicesCourses().createCourse(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(createSuccess(response.data));
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const updateCourse = (id, data, handleClose, formikHelpers) => {
  return async (dispatch) => {
    try {
      const response = await servicesCourses().updateCourse(id, data);
      response.name === 'update' && response.changes
        ? await loadCourseUpdate()
        : dispatch(updateSuccess());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
    async function loadCourseUpdate() {
      const respShowCourse = await servicesCourses().showCourse(id);
      dispatch(updateSuccess(respShowCourse.data));
    }
  };
};

export const getCourse = (id) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await servicesCourses().showCourse(id);
      dispatch(setDetailCourse(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const deleteCourse = (id) => {
  return async (dispatch) => {
    try {
      const response = await servicesCourses().deleteCourse(id);
      dispatch(removeSuccess(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getCoursesChildren = ({ idStudent }) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      let response = {
        data: [],
        status: false,
      };
      response = await servicesCourses().showCourseChildren(idStudent);
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  createSuccess,
  updateSuccess,
  setDetailCourse,
  onSelectCourse,
  removeSuccess,
  clearStateCourse,
} = courseSlice.actions;

export default courseSlice.reducer;

async function loadCoursesChildren(response, idStudent, formikHelpers) {
  response = await servicesCourses().showCourseChildren(idStudent);
  formikHelpers.setSubmitting(false);
  return response;
}
