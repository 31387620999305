import { EventEmitter } from 'events';
import { LogEntry } from '.';
import { LEVEL, MODULE } from '../enums';

export class Logger {
  private logManager: EventEmitter;
  private minLevel: number;
  private module: MODULE;
  private readonly levels: { [key: string]: number } = {
    trace: 1,
    debug: 2,
    info: 3,
    warn: 4,
    error: 5,
  };

  constructor(logManager: EventEmitter, module: MODULE, minLevel: string) {
    this.logManager = logManager;
    this.module = module;
    this.minLevel = this.levelToInt(minLevel);
  }

  /**
   * Converts a string level (trace/debug/info/warn/error) into a number
   *
   * @param minLevel
   */
  private levelToInt(minLevel: string): number {
    if (minLevel.toLowerCase() in this.levels) return this.levels[minLevel.toLowerCase()];
    else return 99;
  }

  /**
   * Central logging method.
   * @param logLevel
   * @param message
   */
  public log(logLevel: LEVEL, message: string): void {
    const level = this.levelToInt(logLevel);
    if (level < this.minLevel) return;

    const logEntry: LogEntry = { level: logLevel, module: this.module, message };

    // Obtain the line/file through a thoroughly hacky method
    // This creates a new stack trace and pulls the caller from it.  If the caller
    // if .trace()
    const error = new Error('');
    if (error.stack) {
      const cla = error.stack.split('\n');
      let idx = 1;
      while (idx < cla.length && cla[idx].includes('at Logger.Object.')) idx++;
      if (idx < cla.length) {
        logEntry.location = cla[idx].slice(cla[idx].indexOf('at ') + 3, cla[idx].length);
      }
    }

    this.logManager.emit('log', logEntry);
  }

  public trace(message: string): void {
    this.log(LEVEL.TRACE, message);
  }
  public debug(message: string): void {
    this.log(LEVEL.DEBUG, message);
  }
  public info(message: string): void {
    this.log(LEVEL.INFO, message);
  }
  public warn(message: string): void {
    this.log(LEVEL.WARN, message);
  }
  public error(message: string): void {
    this.log(LEVEL.ERROR, message);
  }
}
