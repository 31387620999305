import {
  FaHome as HomeIcon,
  FaBars as MenuIcon,
  FaSearch as SearchIcon,
  FaCog as SettingsIcon,
  FaPlus as AddIcon,
  FaBook,
  FaListUl,
  FaSpellCheck,
  FaTimes,
} from 'react-icons/fa';
import { FaCirclePlus, FaTrashCan, FaPencil } from 'react-icons/fa6';
import { MdSend } from 'react-icons/md';
// import { FcSettings } from 'react-icons/fc';

import { TYPE_ICONS } from '../../../utils/enums/enums';

const IconsLibrary = ({ name = TYPE_ICONS.HOME_ICON, ...props }) => {
  const icons = {
    [TYPE_ICONS.HOME_ICON]: <HomeIcon {...props} />,
    [TYPE_ICONS.MENU_ICON]: <MenuIcon {...props} />,
    [TYPE_ICONS.SEARCH_ICON]: <SearchIcon {...props} />,
    [TYPE_ICONS.SETTING_ICON]: <SettingsIcon {...props} />,
    [TYPE_ICONS.ADD_ICON]: <AddIcon {...props} />,
    [TYPE_ICONS.PLUS_ICON]: <FaCirclePlus {...props} />,
    [TYPE_ICONS.TRASH_ICON]: <FaTrashCan {...props} />,
    [TYPE_ICONS.BOOK_ICON]: <FaBook {...props} />,
    [TYPE_ICONS.PENCIL_ICON]: <FaPencil {...props} />,
    [TYPE_ICONS.LIST_ICON]: <FaListUl {...props} />,
    [TYPE_ICONS.SEND_ICON]: <MdSend {...props} />,
    [TYPE_ICONS.CHECK_ICON]: <FaSpellCheck {...props} />,
    [TYPE_ICONS.CLOSE_ICON]: <FaTimes {...props} />,
  };

  return icons[name];
};

export default IconsLibrary;
