/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { TRANSPARENT } from '../../base/colors';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const stepper = () => ({
  MuiStepper: {
    styleOverrides: {
      root: {
        margin: `${pxToRem(48)} 0`,
        padding: `0 ${pxToRem(12)}`,

        '&.MuiPaper-root': {
          backgroundColor: TRANSPARENT.main,
        },
      },
    },
  },
});

export default stepper;
