import { Fragment, lazy, useCallback, useEffect } from 'react';
import { Navigate, Route, Routes as SwitchRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAuthState, useAppDispatch } from '../common/hooks';
import { getProfile } from '../redux/reducers/profile';
import { authServices } from '../services/authentication';
import { setCsrfToken } from '../redux/reducers/auth';
import { ListMenuPrivate, ListMenuPublic } from './menu';
import { SuspenseLayout, SuspenseLoader } from '../components/atoms/Suspenses';

const OnlyLayout = lazy(() => import('../components/templates/OnlyLayout'));
const DashboardLayout = lazy(() => import('../components/templates/DashboardLayout'));
const Error404Page = lazy(() => import('../views/page404'));
const SideBar = lazy(() => import('../components/organisms/SideBar'));
const NavBar = lazy(() => import('../components/organisms/NavBar'));

export default function Routes() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useSelector((rootState) => getAuthState(rootState));

  const { authenticated } = auth;

  const callbackSetProfile = useCallback(() => void dispatch(getProfile()), [dispatch]);

  const fetchCSRFToken = useCallback(async () => {
    const result = await authServices().getCsrfToken();

    dispatch(setCsrfToken(result.CSRFToken));
  }, [dispatch]);

  useEffect(() => {
    callbackSetProfile();
  }, [callbackSetProfile]);

  useEffect(() => {
    if (authenticated) {
      fetchCSRFToken();
    }
  }, [authenticated, fetchCSRFToken]);

  return (
    <>
      {authenticated && (
        <SuspenseLoader>
          <SideBar />
          <NavBar />
        </SuspenseLoader>
      )}
      <SuspenseLayout>
        <SwitchRouter>
          <Route path='/' element={<OnlyLayout />}>
            {ListMenuPublic.map((menu) => (
              <Route key={menu.id} {...menu} />
            ))}
          </Route>
          <Route path='/dashboard' element={<DashboardLayout />}>
            {ListMenuPrivate(t).map(({ id, path, element, children }) => {
              return (
                <Fragment key={id}>
                  <Route key={id} path={path} element={element} />
                  {children &&
                    children.map((child) => (
                      <Route key={child.id} path={child.path} element={child.element} />
                    ))}
                </Fragment>
              );
            })}
          </Route>
          <Route path='404' element={<Error404Page />} />
          <Route path='*' element={<Navigate to='/404' />} />
        </SwitchRouter>
      </SuspenseLayout>
    </>
  );
}
