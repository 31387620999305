import React from 'react';
import { useAppContext } from '../../contexts/app/context';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ScrollToTop } from '../../components/atoms/ScrollBar';
import Routes from '../../routes';
import { ThemeUI } from '../../utils/theme';

const Setup = () => {
  const { theme } = useAppContext();

  return (
    <ThemeProvider theme={ThemeUI(theme)}>
      <CssBaseline />
      <ScrollToTop />
      <Routes />
    </ThemeProvider>
  );
};

export default Setup;
