import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import { getDetailschoolService, updateSchoolService } from '../../../../services/schools';

const initialState = {
  loadingFormSchool: false,
  detailSchool: {
    _id: '',
    name: '',
    address: {
      street: '',
      ext: '',
      int: '',
      colony: '',
      state: '',
      municipality: '',
      zipCode: '',
    },
  },
};

const schoolsSlice = createSlice({
  name: 'schoolsSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormSchool = true;
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormSchool = false;
      if (action.payload) {
        state.detailSchool = action.payload;
      }
    },
    onSelectSchool: (state, action) => {
      state.selectedIdSchool = action.payload;
    },
    setDetailSchool: (state, action) => {
      state.detailSchool = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormSchool = false;
    },
    clearStateSchool: (state) => {
      state.loadingFormSchool = false;
      state.detailSchool = null;
      state.selectedIdSchool = null;
    },
  },
});

export const updatedSchool = (id, data, actionForm) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateSchoolService(id, data);
      if (response.changes) {
        const responseDetail = await getDetailschoolService(id);
        dispatch(successUpdate(responseDetail.data));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    } finally {
      actionForm.setSubmitting(false);
    }
  };
};

export const getDetailSchool = (id) => {
  return async (dispatch) => {
    try {
      const response = await getDetailschoolService(id);
      dispatch(setDetailSchool(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const { begin, failure, onSelectSchool, setDetailSchool, successUpdate, clearStateSchool } =
  schoolsSlice.actions;

export default schoolsSlice.reducer;
