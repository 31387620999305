/* eslint-disable camelcase */
import { API } from '../config';
import { ROLE_PROFILE, Role } from '../redux/reducers/types';
import { AdminDTOType, AdminPayLoadType, AdminType } from '../redux/reducers/abc/admins/types';
import { ParentDTOType, ParentPayLoadType, ParentType } from '../redux/reducers/abc/parents/types';
import {
  ChildrenDTOType,
  ChildrenType,
  StudentDTOType,
  StudentPayLoadType,
  StudentType,
} from '../redux/reducers/abc/students/types';
import { TeacherDTOType, TeacherPayloadType, TeacherType } from '../redux/reducers/abc/teachers/types';

export const saveUserService = async (
  data: AdminPayLoadType | TeacherPayloadType | ParentPayLoadType | StudentPayLoadType,
) => {
  const res = await API.post('users/addUser', data);
  return await res.data;
};

export const updateUserService = async (
  id: string,
  data: AdminPayLoadType | TeacherPayloadType | ParentPayLoadType | StudentPayLoadType,
) => {
  const res = await API.put(`users/updateUser/${id}`, data);
  return await res.data;
};

export const deleteUserService = async (id: string) => {
  const res = await API.delete(`users/deleteUser/${id}`);
  return await res.data;
};

const getUsersService = async (role: ROLE_PROFILE) => {
  const res = await API.get(`users/getUsers/${role}`);
  return await res.data;
};

const getDetailUserService = async (id: string, role: Role) => {
  const res = await API.get(`users/getUser/${id}/${role}`);
  return await res.data;
};

function transformAdmin({ admin_id, ...rest }: AdminDTOType): AdminType {
  return {
    adminId: admin_id,
    ...rest,
  };
}

function transformTeacher({
  teacher_id,
  email_verification,
  identification_card,
  ...rest
}: TeacherDTOType): TeacherType {
  return {
    teacherId: teacher_id,
    emailVerification: email_verification,
    identificationCard: identification_card,
    ...rest,
  };
}

export function transformChildren({ _id, idParent, ...rest }: ChildrenDTOType): ChildrenType {
  return {
    studentId: _id,
    idParent: {
      parentId: idParent._id,
      idUser: idParent.idUser,
      ocuppation: idParent.ocuppation,
      children: idParent.children,
    },
    ...rest,
  };
}

function transformParent({ parent_id, children, ...rest }: ParentDTOType): ParentType {
  return {
    parentId: parent_id,
    children: children.map(transformChildren),
    ...rest,
  };
}

function transformStudent({ student_id, idParent, ...rest }: StudentDTOType): StudentType {
  return {
    studentId: student_id,
    idParent: idParent
      ? {
          parentId: idParent._id,
          idUser: idParent.idUser,
          ocuppation: idParent.ocuppation,
          children: idParent.children,
        }
      : null,
    ...rest,
  };
}

export async function serviceGetAdministrators(): Promise<AdminType[]> {
  const response = await getUsersService(ROLE_PROFILE.ADMIN);
  const data: AdminDTOType[] = response.data;
  const result = data.map(transformAdmin);

  return result;
}

export async function serviceGetAdministrator(id: string): Promise<AdminType> {
  const response = await getDetailUserService(id, ROLE_PROFILE.ADMIN);
  const data: AdminDTOType = response.data;
  const result = transformAdmin(data);

  return result;
}

export async function serviceGetTeachers(): Promise<TeacherType[]> {
  const response = await getUsersService(ROLE_PROFILE.TEACHER);
  const data: TeacherDTOType[] = response.data;
  const result = data.map(transformTeacher);

  return result;
}

export async function serviceGetTeacher(id: string): Promise<TeacherType> {
  const response = await getDetailUserService(id, ROLE_PROFILE.TEACHER);
  const data: TeacherDTOType = response.data;
  const result = transformTeacher(data);

  return result;
}

export async function serviceGetParents(): Promise<ParentType[]> {
  const response = await getUsersService(ROLE_PROFILE.PARENT);
  const data: ParentDTOType[] = response.data;
  const result = data.map(transformParent);

  return result;
}

export async function serviceGetParent(id: string): Promise<ParentType> {
  const response = await getDetailUserService(id, ROLE_PROFILE.PARENT);
  const data: ParentDTOType = response.data;
  const result = transformParent(data);

  return result;
}

export async function serviceGetStudents(): Promise<StudentType[]> {
  const response = await getUsersService(ROLE_PROFILE.STUDENT);
  const data: StudentDTOType[] = response.data;
  const result = data.map(transformStudent);

  return result;
}

export async function serviceGetStudent(id: string): Promise<StudentType> {
  const response = await getDetailUserService(id, ROLE_PROFILE.STUDENT);
  const data: StudentDTOType = response.data;
  const result = transformStudent(data);

  return result;
}
