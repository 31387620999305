import { createSlice } from '@reduxjs/toolkit';
import { clearFormData } from '../../../utils';
import { createAlert } from '../alert';
import { servicesActivities } from '../../../services/activities';

const initialState = {
  loadingActivities: false,
  dataActivities: [],
  selectedActivity: null,
  selectedActivityId: null,
};

const activitiesSlice = createSlice({
  name: 'activitiesSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingActivities = true;
    },
    all: (state, action) => {
      state.loadingActivities = false;
      state.dataActivities = action.payload;
    },
    failure: (state) => {
      state.loadingActivities = false;
    },
    createSuccess: (state, action) => {
      if (action.payload) {
        const newItem = action.payload;
        const Items = state.dataActivities;
        Items.push(newItem);
        state.dataActivities = Items;
      }
    },
    updateSuccess: (state, action) => {
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataActivities.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataActivities = items;
      }
    },
    setSelectActivity: (state, action) => {
      state.selectedActivity = action.payload;
    },
    setSelectActivityId: (state, action) => {
      state.selectedActivityId = action.payload;
    },
    removeSuccess: (state, action) => {
      state.dataActivities = state.dataActivities.filter((item) => item._id !== action.payload);
    },
    clearSelectActivity: (state) => {
      state.selectedActivity = null;
    },
    clearStateActivities: (state) => {
      state.loadingActivities = false;
      state.dataActivities = [];
      state.selectedActivity = null;
      state.selectedActivityId = null;
    },
    removeDocumentActivity: (state, action) => {
      if (state.selectedActivity) {
        const idDocument = action.payload;
        state.selectedActivity = {
          ...state.selectedActivity,
          idAttachments: [
            ...state.selectedActivity.idAttachments.filter(
              (attachment) => attachment._id !== idDocument,
            ),
          ],
        };
      }
    },
  },
});

export const getActivities = () => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await servicesActivities().showActivities();
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getActivitiesCourse = (idCourse) => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await servicesActivities().showActivitiesCourse(idCourse);
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getActivitiesChildren = (idGrade) => {
  return async (dispatch) => {
    try {
      dispatch(begin());
      const response = await servicesActivities().showActivityChildren(idGrade);
      dispatch(all(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const getActivity = (id) => {
  return async (dispatch) => {
    try {
      const response = await servicesActivities().showActivity(id);
      dispatch(setSelectActivity(response.data));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const createActivity = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    try {
      const response = await servicesActivities().createActivity(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(createSuccess(response.data));
      clearFormData(data);
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const updateActivity = (id, data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    try {
      const response = await servicesActivities().updateActivity(id, data);
      response.name === 'update' && response.changes
        ? await loadActivityUpdate()
        : dispatch(updateSuccess());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
      formikHelpers.resetForm();
      clearFormData(data);
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
    async function loadActivityUpdate() {
      const response = await servicesActivities().showActivity(id);
      dispatch(updateSuccess(response.data));
    }
  };
};

export const deleteActivity = (id) => {
  return async (dispatch) => {
    try {
      const response = await servicesActivities().deleteActivity(id);
      if (response.status) {
        dispatch(removeSuccess(id));
        dispatch(createAlert({ message: response.message, status: 'success' }));
        dispatch(clearSelectActivity());
      }
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const deleteDocumentActivity = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await servicesActivities().deleteDocumentActivity(id, data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(removeDocumentActivity(data.idAttachment));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  createSuccess,
  updateSuccess,
  setSelectActivity,
  setSelectActivityId,
  removeSuccess,
  clearSelectActivity,
  clearStateActivities,
  removeDocumentActivity,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
