import { noop } from 'lodash';
import { createContext, useContext } from 'react';

export const initialState = {
  students: {
    isLoading: false,
    data: [],
  },
  studentsAttendance: {
    isLoading: false,
    data: [],
  },
  assistanceId: null,
  selectedAssistance: null,
  childrenSelected: null,
  courseSelected: null,
  setStudentsByCourse: noop,
  setAssistanceId: noop,
  setDetailAssistance: noop,
  setStudentsAttendance: noop,
  setChildrenSelected: noop,
  setCourseSelected: noop,
};

export const AssistancesContext = createContext(initialState);

export const useAssistancesContext = () => {
  const context = useContext(AssistancesContext);
  return context;
};
