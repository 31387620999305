/**
 * Effect wrapper arount localStorage.getItem
 */
const getItem = (key: string) => {
  return localStorage.getItem(key);
};

/**
 * Effect wrapper arount localStorage.setItem
 */
const setItem = async (key: string, value: string) => {
  return Promise.resolve(localStorage.setItem(key, value));
};

/**
 * Effect wrapper arount localStorage.removeItem
 */
const removeItem = async (key: string) => {
  return Promise.resolve(localStorage.removeItem(key));
};

const removeAll = async () => {
  return localStorage.clear();
};

export const storage = { getItem, setItem, removeItem, removeAll };
