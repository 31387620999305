import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const initialState = {
  alerts: [],
};

export const alertsSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlerts: (state, action) => {
      state.alerts.push({
        id: nanoid(),
        message: action.payload.message,
        status: action.payload.status,
      });
    },
    dismissAlerts: (state, action) => {
      const idx = state.alerts.findIndex((alert) => alert.id === action.payload);
      if (idx !== -1) {
        state.alerts.splice(idx, 1);
      }
    },
    createAlert: (state, action) => {
      state.alert = {
        id: nanoid(),
        ...action.payload,
      };
    },
    dismissAlert: (state, action) => {
      if (action.payload === state.alert?.id) state.alert = undefined;
    },
  },
});

export const { createAlerts, dismissAlerts, createAlert, dismissAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
