import { useCallback, useMemo, useReducer } from 'react';
import { node, shape, string } from 'prop-types';
import { AppContext, initialState } from './context';
import { ACTIONS, AppContextReducer } from './reducers';

const AppContextProvider = ({ values, children }) => {
  const [state, dispatch] = useReducer(AppContextReducer, initialState);
  const { locale } = values;

  const onToggleSidebar = useCallback(
    (isOpenSidebar) =>
      dispatch({
        type: ACTIONS.SET_OPEN_SIDEBAR,
        payload: {
          isOpenSidebar,
        },
      }),
    [],
  );

  const setLocale = useCallback(
    () =>
      dispatch({
        type: ACTIONS.CHANGE_LOCALE,
        payload: { locale },
      }),
    [locale],
  );

  const changeTheme = useCallback(
    (theme) =>
      dispatch({
        type: ACTIONS.CHANGE_THEME,
        payload: {
          theme,
        },
      }),
    [],
  );

  const setTransparentNavbar = useCallback((transparentNavbar) => {
    dispatch({
      type: ACTIONS.SET_TRANSPARENT_NAVBAR,
      payload: {
        transparentNavbar,
      },
    });
  }, []);

  const setMiniSidenav = useCallback((miniSidenav) => {
    dispatch({
      type: ACTIONS.SET_MINI_SIDENAV,
      payload: {
        miniSidenav,
      },
    });
  }, []);

  const setTransparentSidenav = useCallback((transparentSidenav) => {
    dispatch({
      type: ACTIONS.SET_TRANSPARENT_SIDENAV,
      payload: {
        transparentSidenav,
      },
    });
  }, []);

  const setAdvertisements = useCallback(
    (advertisements) => dispatch({ type: ACTIONS.SET_ADVERTISEMENTS, payload: { advertisements } }),
    [],
  );

  const setAdvertisementId = useCallback(
    (advertisementId) =>
      dispatch({ type: ACTIONS.SET_ADVERTISEMENT_ID, payload: { advertisementId } }),
    [],
  );

  const setDetailAds = useCallback(
    (advertisement) =>
      dispatch({ type: ACTIONS.SET_ADVERTISEMENT_DETAIL, payload: { advertisement } }),
    [],
  );

  const pushItemAdvertisement = useCallback(
    (advertisement) => dispatch({ type: ACTIONS.PUSH_ADVERTISEMENT, payload: { advertisement } }),
    [],
  );

  const removeItemAdvertisement = useCallback(
    (advertisementId) =>
      dispatch({ type: ACTIONS.REMOVE_ADVERTISEMENT, payload: { advertisementId } }),
    [],
  );

  const updateItemAdvertisement = useCallback(
    (advertisement) => dispatch({ type: ACTIONS.UPDATE_ADVERTISEMENT, payload: { advertisement } }),
    [],
  );

  const contextState = useMemo(
    () => ({
      ...state,
      onToggleSidebar,
      setLocale,
      changeTheme,
      setTransparentNavbar,
      setMiniSidenav,
      setTransparentSidenav,
      setAdvertisements,
      setAdvertisementId,
      setDetailAds,
      pushItemAdvertisement,
      removeItemAdvertisement,
      updateItemAdvertisement,
    }),
    [
      state,
      onToggleSidebar,
      setLocale,
      changeTheme,
      setTransparentNavbar,
      setMiniSidenav,
      setTransparentSidenav,
      setAdvertisements,
      setAdvertisementId,
      setDetailAds,
      pushItemAdvertisement,
      removeItemAdvertisement,
      updateItemAdvertisement,
    ],
  );

  return <AppContext.Provider value={contextState}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: node,
  values: shape({ locale: string }),
};

export default AppContextProvider;
