import globals from '../base/globals';
import container from '../components/container';

// Soft UI Dashboard React components base styles for @mui material components
import sidenav from '../components/sidenav';
// import list from '../components/list';
// import listItem from '../components/list/listItem';
// import listItemText from '../components/list/listItemText';
import card from '../components/card';
import cardMedia from '../components/card/cardMedia';
import cardContent from '../components/card/cardContent';
import button from '../components/button';
import iconButton from '../components/iconButton';
import inputBase from '../components/form/inputBase';
import menu from '../components/menu';
import menuItem from '../components/menu/menuItem';
import switchButton from '../components/form/switchButton';
import divider from '../components/divider';
import tableContainer from '../components/table/tableContainer';
import tableHead from '../components/table/tableHead';
import tableCell from '../components/table/tableCell';
import linearProgress from '../components/linearProgress';
import breadcrumbs from '../components/breadcrumbs';
import slider from '../components/slider';
import avatar from '../components/avatar';
import tooltip from '../components/tooltip';
import appBar from '../components/appBar';
import tabs from '../components/tabs';
import tab from '../components/tabs/tab';
import stepper from '../components/stepper';
import step from '../components/stepper/step';
import stepConnector from '../components/stepper/stepConnector';
import stepLabel from '../components/stepper/stepLabel';
import stepIcon from '../components/stepper/stepIcon';
import select from '../components/form/select';
import formControlLabel from '../components/form/formControlLabel';
import formLabel from '../components/form/formLabel';
import checkbox from '../components/form/checkbox';
import radio from '../components/form/radio';
import autocomplete from '../components/form/autocomplete';
import input from '../components/form/input';
import popover from '../components/popover';
import buttonBase from '../components/buttonBase';
import icon from '../components/icon';
import svgIcon from '../components/svgIcon';
import link from '../components/link';

const muiCssBaseline = {
  MuiCssBaseline: {
    styleOverrides: {
      ...globals,
      ...container,
    },
  },
};

const componentsOverride = (theme) => {
  const components = {
    ...muiCssBaseline,
    ...sidenav(theme),
    // ...list(theme),
    // ...listItem(theme),
    // ...listItemText(theme),
    ...card(theme),
    ...cardMedia(theme),
    ...cardContent(theme),
    ...button(theme),
    ...iconButton(theme),
    ...inputBase(theme),
    ...menu(theme),
    ...menuItem(theme),
    ...switchButton(theme),
    ...divider(theme),
    ...tableContainer(theme),
    ...tableHead(theme),
    ...tableCell(theme),
    ...linearProgress(theme),
    ...breadcrumbs(theme),
    ...slider(theme),
    ...avatar(theme),
    ...tooltip(theme),
    ...appBar(theme),
    ...tabs(theme),
    ...tab(theme),
    ...stepper(theme),
    ...step(theme),
    ...stepConnector(theme),
    ...stepLabel(theme),
    ...stepIcon(theme),
    ...select(theme),
    ...formControlLabel(theme),
    ...formLabel(theme),
    ...checkbox(theme),
    ...radio(theme),
    ...autocomplete(theme),
    ...input(theme),
    ...popover(theme),
    ...buttonBase(theme),
    ...icon(theme),
    ...svgIcon(theme),
    ...link(theme),
  };

  return components;
};

export default componentsOverride;
