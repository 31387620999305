import { NotificationsContextInterface, SetNotificationsType } from './context';

export enum ACTIONS {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION',
}

interface NotificationContextReducerInterface {
  type: ACTIONS;
  payload: {
    notifications: SetNotificationsType;
  };
}

export const NotiicationsContextReducer = (
  state: NotificationsContextInterface,
  reducerPayload: NotificationContextReducerInterface,
): NotificationsContextInterface => {
  const { type: actionType, payload } = reducerPayload;
  switch (actionType) {
    case ACTIONS.SET_NOTIFICATIONS: {
      const {
        notifications: { isLoading, currentPage, totalPages, data },
      } = payload;
      return {
        ...state,
        notifications: {
          isLoading,
          currentPage,
          totalPages,
          data: isLoading ? state.notifications.data : data,
        },
      };
    }
    case ACTIONS.SET_NOTIFICATIONS_PAGINATION: {
      const {
        notifications: { isLoading, currentPage, totalPages, data },
      } = payload;
      return {
        ...state,
        notifications: {
          isLoading,
          currentPage,
          totalPages,
          data: [...state.notifications.data, ...data],
        },
      };
    }
  }
};
