/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import boxShadows from '../../base/boxShadows';
import typography from '../../base/typography';
import colors, { BLACK, WHITE } from '../../base/colors';
import borders from '../../base/borders';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';
import { MODE_THEME } from '../../../enums/enums';

const { lg } = boxShadows;
const { size } = typography;
const { text } = colors();
const { borderRadius } = borders;

const menu = (theme) => ({
  MuiMenu: {
    defaultProps: {
      disableAutoFocusItem: true,
    },
    styleOverrides: {
      paper: {
        minWidth: pxToRem(160),
        boxShadow: lg,
        padding: `${pxToRem(16)} ${pxToRem(8)}`,
        fontSize: size.sm,
        color: text.main,
        textAlign: 'left',
        backgroundColor:
          theme.palette.mode === MODE_THEME.DARK
            ? `${BLACK.light} !important`
            : `${WHITE.main} !important`,
        borderRadius: borderRadius.md,
      },
    },
  },
});

export default menu;
