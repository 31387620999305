import { modifyByIndex, removeByIndex, storage } from '../../utils';

const ACTIONS_ADS = {
  SET_ADVERTISEMENTS: 'SET_ADVERTISEMENTS',
  SET_ADVERTISEMENT_ID: 'SET_ADVERTISEMENT_ID',
  SET_ADVERTISEMENT_DETAIL: 'SET_ADVERTISEMENT_DETAIL',
  PUSH_ADVERTISEMENT: 'PUSH_ADVERTISEMENT',
  REMOVE_ADVERTISEMENT: 'REMOVE_ADVERTISEMENT',
  UPDATE_ADVERTISEMENT: 'UPDATE_ADVERTISEMENT',
};

export const ACTIONS = {
  SET_OPEN_SIDEBAR: 'SET_OPEN_SIDEBAR',
  CHANGE_LOCALE: 'CHANGE_LOCALE',
  CHANGE_THEME: 'CHANGE_THEME',
  SET_TRANSPARENT_NAVBAR: 'SET_TRANSPARENT_NAVBAR',
  SET_MINI_SIDENAV: 'SET_MINI_SIDENAV',
  SET_TRANSPARENT_SIDENAV: 'SET_TRANSPARENT_SIDENAV',
  ...ACTIONS_ADS,
};

export const AppContextReducer = (state, reducerPayload) => {
  const { type: actionType, payload } = reducerPayload;

  switch (actionType) {
    case ACTIONS.SET_OPEN_SIDEBAR: {
      const { isOpenSidebar } = payload;

      return {
        ...state,
        isOpenSidebar,
      };
    }
    case ACTIONS.CHANGE_LOCALE: {
      const { locale } = payload;

      return {
        ...state,
        locale,
      };
    }
    case ACTIONS.CHANGE_THEME: {
      const { theme } = payload;
      storage.setItem('@theme', theme);

      return {
        ...state,
        theme,
      };
    }
    case ACTIONS.SET_TRANSPARENT_NAVBAR: {
      const { transparentNavbar } = payload;

      return {
        ...state,
        transparentNavbar,
        navbarType: !transparentNavbar ? 'sticky' : 'static',
      };
    }
    case ACTIONS.SET_MINI_SIDENAV: {
      const { miniSidenav } = payload;

      return {
        ...state,
        miniSidenav,
      };
    }
    case ACTIONS.SET_TRANSPARENT_SIDENAV: {
      const { transparentSidenav } = payload;

      return {
        ...state,
        transparentSidenav,
      };
    }
    case ACTIONS.SET_ADVERTISEMENTS: {
      const {
        advertisements: { isLoading, data },
      } = payload;

      return {
        ...state,
        advertisements: {
          isLoading,
          data: isLoading ? state.advertisements.data : data,
        },
      };
    }
    case ACTIONS.SET_ADVERTISEMENT_ID: {
      const { advertisementId } = payload;

      return {
        ...state,
        advertisementId,
      };
    }
    case ACTIONS.SET_ADVERTISEMENT_DETAIL: {
      const { advertisement } = payload;

      return {
        ...state,
        advertisement,
      };
    }
    case ACTIONS.PUSH_ADVERTISEMENT: {
      const { advertisement } = payload;

      return {
        ...state,
        advertisements: {
          ...state.advertisements,
          data: [...state.advertisements.data, advertisement],
        },
      };
    }
    case ACTIONS.REMOVE_ADVERTISEMENT: {
      const { advertisementId } = payload;
      const {
        advertisements: { data },
      } = state;

      const itemIndex = data.findIndex((item) => item.advertisementId === advertisementId);

      const itemsModified = removeByIndex(itemIndex, data);

      return {
        ...state,
        advertisements: {
          ...state.advertisements,
          data: itemsModified,
        },
      };
    }
    case ACTIONS.UPDATE_ADVERTISEMENT: {
      const { advertisement } = payload;
      const {
        advertisements: { data },
      } = state;

      const itemIndex = data.findIndex(
        (item) => item.advertisementId === advertisement.advertisementId,
      );
      const itemsModified = modifyByIndex(itemIndex, data, advertisement);

      return {
        ...state,
        advertisements: {
          ...state.advertisements,
          data: itemsModified,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
