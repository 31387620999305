import { styled } from '@mui/material/styles';
import { images } from '../../../constants';
import { TYPE_IMAGE_ICONS } from '../../../utils/enums/enums';

const ImageStyle = styled('img')(({ width, height, ...props }) => ({
  width: width,
  height: height,
  ...props,
}));

const ImageIconsLibrary = ({ name = TYPE_IMAGE_ICONS.HOME_ICON, ...props }) => {
  const Icons = {
    [TYPE_IMAGE_ICONS.HOME_ICON]: <ImageStyle src={images.home} {...props} />,
    [TYPE_IMAGE_ICONS.ABC_ICON]: <ImageStyle src={images.abc} {...props} />,
    [TYPE_IMAGE_ICONS.COURSES_ICON]: <ImageStyle src={images.courses} {...props} />,
    [TYPE_IMAGE_ICONS.COURSES_ICON]: <ImageStyle src={images.score} {...props} />,
    [TYPE_IMAGE_ICONS.REPORT_CARD_ICON]: <ImageStyle src={images.reportCard} {...props} />,
    [TYPE_IMAGE_ICONS.SHOP_ICON]: <ImageStyle src={images.shoppingCart} {...props} />,
    [TYPE_IMAGE_ICONS.LOGOUT_ICON]: <ImageStyle src={images.logout} {...props} />,
    [TYPE_IMAGE_ICONS.SETTINGS_ICON]: <ImageStyle src={images.setting} {...props} />,
    [TYPE_IMAGE_ICONS.PROFILE_ICON]: <ImageStyle src={images.profile} {...props} />,
    [TYPE_IMAGE_ICONS.MOON_ICON]: <ImageStyle src={images.moon} {...props} />,
    [TYPE_IMAGE_ICONS.SUN_ICON]: <ImageStyle src={images.sun} {...props} />,
    [TYPE_IMAGE_ICONS.NOTIFICATION_ICON]: <ImageStyle src={images.notification} {...props} />,
    [TYPE_IMAGE_ICONS.ES_ICON]: <ImageStyle src={images.es} {...props} />,
    [TYPE_IMAGE_ICONS.EN_ICON]: <ImageStyle src={images.en} {...props} />,
    [TYPE_IMAGE_ICONS.ADVERTISEMENT_ICON]: <ImageStyle src={images.advertisement} {...props} />,
    [TYPE_IMAGE_ICONS.ASSISTENCE_ICON]: <ImageStyle src={images.assistence} {...props} />,
  };

  return Icons[name];
};

export default ImageIconsLibrary;
