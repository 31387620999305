import { createSlice } from '@reduxjs/toolkit';
import {
  deleteUserService,
  saveUserService,
  serviceGetStudent,
  serviceGetStudents,
  updateUserService,
} from '../../../../services/users';
import { createAlert } from '../../alert';

const initialState = {
  loadingFormStudent: false,
  loadingStudents: false,
  dataStudents: [],
  selectedChildren: null,
};

const studentsSlice = createSlice({
  name: 'studentsSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormStudent = true;
      state.loadingStudents = true;
    },
    all: (state, action) => {
      state.loadingFormStudent = false;
      state.loadingStudents = false;
      state.dataStudents = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormStudent = false;
      state.loadingStudents = false;
      if (action.payload) {
        const Items = state.dataStudents;
        Items.push(action.payload);
        state.dataStudents = Items;
      }
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormStudent = false;
      state.loadingStudents = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataStudents.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataStudents = items;
      }
    },
    successRemove: (state, action) => {
      state.dataStudents = state.dataStudents.filter((item) => item._id !== action.payload);
    },
    onSelectStudent: (state, action) => {
      state.selectedIdStudent = action.payload;
    },
    setDetailStudent: (state, action) => {
      state.detailStudent = action.payload;
    },
    setSelectedChildren: (state, action) => {
      state.selectedChildren = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormStudent = false;
      state.loadingStudents = false;
    },
    clearStateStudent: (state) => {
      state.loadingFormStudent = false;
      state.loadingStudents = false;
      state.dataStudents = [];
      state.selectedIdStudent = null;
      state.detailStudent = null;
      state.selectedChildren = null;
    },
  },
});

export const getStudents = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await serviceGetStudents();
      dispatch(all(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveStudent = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveUserService(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const updatedStudent = (id, data, handleClose, formikHelpers) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateUserService(id, data);
      if (response.changes) {
        const responseDetail = await serviceGetStudent(id);
        dispatch(successUpdate(responseDetail));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const deleteStudent = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteUserService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getDetailStudent = (id) => {
  return async (dispatch) => {
    try {
      const response = await serviceGetStudent(id);
      dispatch(setDetailStudent(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectStudent,
  setDetailStudent,
  successUpdate,
  successRemove,
  setSelectedChildren,
  clearStateStudent,
} = studentsSlice.actions;

export default studentsSlice.reducer;
