/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { BLACK, WHITE } from '../base/colors';
import borders from '../base/borders';

// Soft UI Dashboard React helper functions
import rgba from '../functions/rgba';
import pxToRem from '../functions/pxToRem';
import { MODE_THEME } from '../../enums/enums';

const { borderRadius } = borders;

const sidenav = (theme) => ({
  MuiDrawer: {
    styleOverrides: {
      root: {
        width: pxToRem(250),
        whiteSpace: 'nowrap',
        border: 'none',
      },

      paper: {
        width: pxToRem(250),
        backgroundColor: theme.palette.mode === MODE_THEME.DARK ? rgba(BLACK.light, 0.8) : rgba(WHITE.main, 0.8),
        backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
        height: `calc(100vh - ${pxToRem(32)})`,
        margin: pxToRem(16),
        borderRadius: borderRadius.xl,
        border: 'none',
      },

      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
  },
});

export default sidenav;
