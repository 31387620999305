import { API } from '../config';

export const saveSubjectService = async (data) => {
  const res = await API.post('subjects/add', data);
  return await res.data;
};

export const updateSubjectService = async (id, data) => {
  const res = await API.put(`subjects/edit/${id}`, data);
  return await res.data;
};

export const deleteSubjectService = async (id) => {
  const res = await API.delete(`subjects/delete/${id}`);
  return await res.data;
};

export const getSubjectsService = async () => {
  const res = await API.get('subjects/list');
  return await res.data;
};

export const getDetailSubjectService = async (id) => {
  const res = await API.get(`subjects/detail/${id}`);
  return await res.data;
};
