import { useState } from 'react';

export const UseHookDialog = () => {
  const [openDialog, setopenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');

  const handleOpenDialog = () => {
    setopenDialog(true);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  return {
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    titleDialog,
    setTitleDialog,
  };
};
