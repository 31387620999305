/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import borders from '../../base/borders';
import { TRANSPARENT, GRADIENTS, INFO } from '../../base/colors';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';
import linearGradient from '../../functions/linearGradient';

const { borderWidth, borderColor } = borders;

const radio = () => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: pxToRem(20),
        height: pxToRem(20),
        marginRight: pxToRem(6),
        padding: 0,
        color: TRANSPARENT.main,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: '50%',
        transition: 'all 250ms ease',

        '&:hover': {
          backgroundColor: TRANSPARENT.main,
        },

        '& .MuiSvgIcon-root': {
          fill: TRANSPARENT.main,
        },

        '&.Mui-focusVisible': {
          border: `${borderWidth[2]} solid ${INFO.main} !important`,
        },
      },

      colorPrimary: {
        backgroundColor: TRANSPARENT.main,

        '&.Mui-checked': {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
            GRADIENTS.dark.main,
            GRADIENTS.dark.state,
          )}`,
          borderColor: GRADIENTS.dark.main,
        },

        '&:hover': {
          backgroundColor: TRANSPARENT.main,
        },
      },

      colorSecondary: {
        backgroundColor: TRANSPARENT.main,

        '&.Mui-checked': {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
            GRADIENTS.dark.main,
            GRADIENTS.dark.state,
          )}`,
          borderColor: GRADIENTS.dark.main,
        },

        '&:hover': {
          backgroundColor: TRANSPARENT.main,
        },
      },
    },
  },
});

export default radio;
