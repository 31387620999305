import { clearStateActivities } from '../redux/reducers/activities';
import { createAlert } from '../redux/reducers/alert';
import { success, successLogout } from '../redux/reducers/auth';
import { API } from './api';
import { env } from './env';
import { MODE, MODULE } from '../utils/enums';
import { logging } from '../utils/logger';

const requestHandler = async (request, store) => {
  const state = store.getState();

  request.withCredentials = true;

  if (state.auth.csrfToken) {
    request.headers = {
      'X-CSRF-TOKEN': state.auth.csrfToken,
    };
  }

  return request;
};

const successhandler = async (response, dispatch) => {
  if (response.config.url) {
    const url = response.config.url;
    if (url.includes('profile/changePwd')) {
      dispatch(success({ type: 'logout', response: '' }));
      dispatch(clearStateActivities());
    }
  }
  return response;
};

const errorHandler = (error, dispatch) => {
  const logger = logging.getLogger(MODULE.ERROR);
  const { message, response, config } = error;
  let alertMessageTxt = '';
  if (message.includes('Network Error')) {
    alertMessageTxt = 'Error de red o servidores.';
  } else if (message.includes('timeout of  ms exceeded')) {
    alertMessageTxt = 'Tiempo excedido de ejecución, error de red o servidores.';
  } else {
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        alertMessageTxt = 'No autorizado.';

        dispatch(successLogout());
      } else if (status === 404) {
        alertMessageTxt = 'Falta la ruta API o no está definida';
      } else if (status === 405) {
        alertMessageTxt = 'Método de ruta API no permitido';
      } else if (status === 422) {
        alertMessageTxt = 'El recurso bloqueado y no se puede entregar al navegador.';
      } else if (status >= 500) {
        alertMessageTxt = 'Error del Servidor';
      }
      if (data.message) {
        alertMessageTxt = data.message;
      }
    }
  }

  if (env.mode === MODE.DEV) {
    logger.error(`${config?.url} ::. ${alertMessageTxt}`);
  }

  dispatch(createAlert({ message: alertMessageTxt, status: 'error' }));
  return Promise.reject({ ...error });
};

export const setUpInterceptor = (store) => {
  const { dispatch } = store;

  API.interceptors.request.use(
    (request) => requestHandler(request, store),
    (error) => errorHandler(error, dispatch),
  );

  API.interceptors.response.use(
    (response) => successhandler(response, dispatch),
    (error) => errorHandler(error, dispatch),
  );
};
