/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import typography from '../../base/typography';

const { size } = typography;

const formLabel = () => ({
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: size.sm,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: '-5px'
      },
    },
  }
});

export default formLabel;
