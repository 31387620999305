/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React Button Styles
import root from './root';
import contained from './contained';
import outlined from './outlined';
import buttonText from './text';

const button = () => ({
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: { ...root },
      contained: { ...contained.base },
      containedSizeSmall: { ...contained.small },
      containedSizeLarge: { ...contained.large },
      containedPrimary: { ...contained.primary },
      containedSecondary: { ...contained.secondary },
      outlined: { ...outlined.base },
      outlinedSizeSmall: { ...outlined.small },
      outlinedSizeLarge: { ...outlined.large },
      outlinedPrimary: { ...outlined.primary },
      outlinedSecondary: { ...outlined.secondary },
      text: { ...buttonText.base },
      textSizeSmall: { ...buttonText.small },
      textSizeLarge: { ...buttonText.large },
      textPrimary: { ...buttonText.primary },
      textSecondary: { ...buttonText.secondary },
    },
  },
});

export default button;
