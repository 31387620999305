/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Fade from '@mui/material/Fade';

// Soft UI Dashboard React base styles
import { BLACK, LIGHT } from '../base/colors';
import typography from '../base/typography';
import borders from '../base/borders';

// Soft UI Dashboard React helper functions
import pxToRem from '../functions/pxToRem';

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const tooltip = () => ({
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      // TransitionComponent: Fade,
    },
    styleOverrides: {
      tooltip: {
        maxWidth: pxToRem(200),
        backgroundColor: BLACK.main,
        color: LIGHT.main,
        fontSize: size.sm,
        fontWeight: fontWeightRegular,
        textAlign: 'center',
        borderRadius: borderRadius.md,
        opacity: 0.7,
        padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
      },
      arrow: {
        color: BLACK.main,
      },
    },
  },
});

export default tooltip;
