import { MODE } from '../../../utils/enums/enums';

export const devEnv = {
  mode: MODE.DEV,
  api: process.env.REACT_APP_API_DEV ?? 'https://backend-colegios-test.herokuapp.com/',
  debugIP: process.env.REACT_APP_REDUX_DEBUG_IP ?? false,
  paginationNumber: process.env.REACT_APP_PAGINATION
    ? parseInt(process.env.REACT_APP_PAGINATION)
    : 10,
};
