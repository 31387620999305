import { API } from '../config';

export const getProfileUser = async () => {
  const res = await API.get('profile/profile');
  return await res.data;
};

export const updateProfileUser = async (id, data) => {
  const res = await API.put(`profile/editProfile/${id}`, data);
  return await res.data;
};

export const changePwdService = async (data) => {
  const res = await API.put('profile/changePwd', data);
  return await res.data;
};
