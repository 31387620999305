import { API } from '../config';

export const savePeriodService = async (data) => {
  const res = await API.post('periods/add', data);
  return await res.data;
};

export const updatePeriodService = async (id, data) => {
  const res = await API.put(`periods/edit/${id}`, data);
  return await res.data;
};

export const deletePeriodService = async (id) => {
  const res = await API.delete(`periods/delete/${id}`);
  return await res.data;
};

export const getPeriodsService = async () => {
  const res = await API.get('periods/list');
  return await res.data;
};

export const getDetailPeriodService = async (id) => {
  const res = await API.get(`periods/detail/${id}`);
  return await res.data;
};
