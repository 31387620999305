const path = process.env.PUBLIC_URL;
export const images = {
  LogoColeview: `${path}/images/logo-coleview.svg`,
  LogoIllustration: path + '/images/illustration_login.png',
  RegisterIllustration: path + '/images/illustration_register.png',
  avatarDefault: path + '/images/avatars/avatar_default.jpg',
  avatar2: path + '/images/avatars/avatar_2.jpg',
  avatarIllustration: path + '/images/illustration_avatar.png',
  iconStudent: path + '/images/roles/students.webp',
  iconTeacher: path + '/images/roles/teachers.webp',
  iconParents: path + '/images/roles/parents.webp',
  flagEnSVG: path + '/images/svg/ic_flag_en.svg',
  flagDeSVG: path + '/images/svg/ic_flag_de.svg',
  flagFrSVG: path + '/images/svg/ic_flag_fr.svg',
  notificationPackageSVG: path + '/images/svg/ic_notification_package.svg',
  notificationShippingSVG: path + '/images/svg/ic_notification_shipping.svg',
  notificationMailSVG: path + '/images/svg/ic_notification_mail.svg',
  notificationChatSVG: path + '/images/svg/ic_notification_chat.svg',
  Illustration404SVG: path + '/images/svg/illustration_404.svg',
  home: path + '/images/flatIcons/home.svg',
  abc: path + '/images/flatIcons/abc.svg',
  courses: path + '/images/flatIcons/courses.svg',
  score: path + '/images/flatIcons/score.svg',
  reportCard: path + '/images/flatIcons/evaluation.svg',
  shoppingCart: path + '/images/flatIcons/shoppingCart.svg',
  graduation: path + '/images/flatIcons/graduation.svg',
  logout: path + '/images/flatIcons/logout.svg',
  setting: path + '/images/flatIcons/settings.svg',
  profile: path + '/images/flatIcons/profile.svg',
  moon: path + '/images/flatIcons/moon.svg',
  sun: path + '/images/flatIcons/sun.svg',
  notification: path + '/images/flatIcons/notification.svg',
  en: path + '/images/flatIcons/unitedstates.svg',
  es: path + '/images/flatIcons/mexico.svg',
  gradegroup: path + 'images/services/grados_grupos.svg',
  asignedClass: path + 'images/services/asignacion_clases.svg',
  asignedCourseTeacher: path + 'images/services/profesor_curso_asignado.svg',
  filterTaskCourse: path + 'images/services/filtrar_cursos_tareas.svg',
  parentsAccess: path + 'images/services/acceso_padres.svg',
  whoDeliverableTask: path + 'images/services/quien_entrego_tarea.svg',
  parentThatCourse: path + 'images/services/padre_familia_enterado_que cursos.svg',
  communicationParentToTeacher: path + 'images/services/comunicacion_padre_profesor.svg',
  slider1: path + 'images/landing/slider-1.png',
  slider2: path + 'images/landing/slider-2.png',
  slider3: path + 'images/landing/slider-3.png',
  service1: path + 'images/landing/service-1.png',
  service2: path + 'images/landing/service-2.png',
  service3: path + 'images/landing/service-3.png',
  service4: path + 'images/landing/service-4.png',
  contact1: path + 'images/landing/contact-1.png',
  contact2: path + 'images/landing/contact-2.png',
  contact3: path + 'images/landing/contact-3.png',
  advertisement: path + '/images/flatIcons/advertisement.svg',
  assistence: path + '/images/flatIcons/assistence.svg',
};
