import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getAlertsState } from './common/hooks';
import AppContextProvider from './contexts/app/provider';
import Setup from './config/setup';
import AssistancesContextProvider from './contexts/assistances/provider';

function App() {
  const { i18n } = useTranslation();

  const alerts = useSelector((rootState) => getAlertsState(rootState));

  const { enqueueSnackbar } = useSnackbar();

  const viewAlert = useCallback(() => {
    if (alerts?.alert)
      enqueueSnackbar(alerts.alert.message, {
        key: alerts.alert.id,
        variant: alerts.alert.status,
        autoHideDuration: 6000,
        transitionDuration: { enter: 400, exit: 400 },
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
  }, [alerts?.alert, enqueueSnackbar]);

  useEffect(() => viewAlert(), [viewAlert]);

  return (
    <AppContextProvider values={{ locale: i18n.language }}>
      <AssistancesContextProvider>
        <Setup />
      </AssistancesContextProvider>
    </AppContextProvider>
  );
}

export default App;
