import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../alert';
import { changePwdService, getProfileUser, updateProfileUser } from '../../../services/profile';
import { logoutProfile, setAuthenticated } from '../auth';
import { ROLE_PROFILE } from '../../../utils/enums/enums';

export const changePasswordValues = {
  oldPassword: '',
  newPassword: '',
  confirmedPassword: '',
};

export const initialState = {
  loadingProfile: true,
  data: {
    _id: '',
    email: '',
    username: '',
    idSchool: '',
    lastname: '',
    name: '',
    role: ROLE_PROFILE.STUDENT,
    phone: '',
    birthday: '',
    curp: '',
    age: '',
    children: [],
  },
};

const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingProfile = true;
    },
    success: (state) => {
      state.loadingProfile = false;
    },
    setProfile: (state, action) => {
      state.loadingProfile = false;
      if (action.payload) state.data = action.payload;
    },
    failure: (state) => {
      state.loadingProfile = false;
    },
    clearStateProfile: (state) => {
      state.loadingProfile = false;
      state.data = null;
    },
  },
});

export const getProfile = () => {
  return async (dispatch) => {
    try {
      const { data } = await getProfileUser();
      if (data) {
        dispatch(setProfile(data));
        dispatch(setAuthenticated());
      }
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const updatedProfile = (id, data, actionForm) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateProfileUser(id, data);
      if (response.changes) {
        dispatch(setProfile(response.changes));
      }
      dispatch(setProfile());
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (e) {
      dispatch(failure());
    } finally {
      actionForm.setSubmitting(false);
    }
  };
};

export const changePwd = (data, actionForm) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const values = data;
      delete values.confirmedPassword;
      const response = await changePwdService(values);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      actionForm.resetForm();
      dispatch(logoutProfile());
    } catch (e) {
      dispatch(failure());
    } finally {
      actionForm.setSubmitting(false);
    }
  };
};

export const { begin, setProfile, failure, clearStateProfile } = profileSlice.actions;

export default profileSlice.reducer;
