import { createSlice } from '@reduxjs/toolkit';
import { clearStateActivities } from '../activities';
import { createAlert } from '../alert';
import { clearStateCourse } from '../courses';
import { clearStateProfile, getProfile } from '../profile';
import { authServices } from '../../../services/authentication';
import { storage } from '../../../utils';
import { clearStateDeliverable } from '../deliverables';
import { clearStatePeriod } from '../abc/periods';
import { clearStateGroup } from '../abc/groups';
import { clearStateGrade } from '../abc/grades';
import { clearStateSchool } from '../abc/schools';
import { clearStateSubject } from '../abc/subjects';
import { clearStateAdmin } from '../abc/admins';
import { clearStateTeacher } from '../abc/teachers';
import { clearStateStudent } from '../abc/students';
import { clearStateParent } from '../abc/parents';

const initialState = {
  authenticated: false,
  error: false,
  loadingAuth: false,
  roleSelected: null,
  csrfToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingAuth = true;
    },
    success: (state, action) => {
      const { type } = action.payload;
      if (type === 'signup') {
        state.loadingAuth = false;
      } else {
        state.authenticated = false;
      }
    },
    successLogout: (state) => {
      state.authenticated = false;
      state.csrfToken = null;

      storage.removeAll();
    },
    setRoleSignIn: (state, action) => {
      state.roleSelected = action.payload;
    },
    setAuthenticated: (state) => {
      state.authenticated = true;
      state.loadingAuth = false;
    },
    setCsrfToken: (state, action) => {
      state.csrfToken = action.payload;
    },
    failure: (state) => {
      state.loadingAuth = false;
      state.error = true;
    },
  },
});

export const authenticatedUser = (data, formikHelpers) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await authServices().signInClient(data);

      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(getProfile());
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const registerClientAdmin = (data, formikHelpers, navigate) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await authServices().signUpClient(data);

      dispatch(success({ type: 'signup', response: response.message }));
      dispatch(createAlert({ message: response.message, status: 'success' }));
      formikHelpers.resetForm();
      navigate('/login', { replace: true });
    } catch (e) {
      dispatch(failure());
      dispatch(
        createAlert({ message: 'Por favor ingrese la informacion correcta', status: 'error' }),
      );
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      const response = await authServices().logoutClient();

      dispatch(successLogout());
      dispatch(clearStateCourse());
      dispatch(clearStateActivities());
      dispatch(clearStateProfile());
      dispatch(clearStateDeliverable());

      dispatch(clearStatePeriod());
      dispatch(clearStateGroup());
      dispatch(clearStateGrade());
      dispatch(clearStateSchool());
      dispatch(clearStateSubject());
      dispatch(clearStateAdmin());
      dispatch(clearStateTeacher());
      dispatch(clearStateStudent());
      dispatch(clearStateParent());

      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const logoutProfile = () => {
  return (dispatch) => {
    dispatch(successLogout());
    dispatch(clearStateCourse());
    dispatch(clearStateActivities());
    dispatch(clearStateProfile());
    dispatch(clearStateDeliverable());
    dispatch(clearStatePeriod());
    dispatch(clearStateGroup());
    dispatch(clearStateGrade());
    dispatch(clearStateSchool());
    dispatch(clearStateSubject());
    dispatch(clearStateAdmin());
    dispatch(clearStateTeacher());
    dispatch(clearStateStudent());
    dispatch(clearStateParent());

    dispatch(createAlert({ message: 'Vuelve a iniciar sesion por favor.', status: 'success' }));
  };
};

export const {
  begin,
  success,
  failure,
  setRoleSignIn,
  setAuthenticated,
  setCsrfToken,
  successLogout,
} = authSlice.actions;

export default authSlice.reducer;
