import { createTheme } from '@mui/material';
import typography from './typography';
import shadows from './shadows';
// import componentsOverride from './overrides';
import componentsOverride from './components';
import { MODE_THEME } from '../enums/enums';

// Soft UI Dashboard React base styles
import colors, { BACKGROUND_COLOR } from './base/colors';
import breakpoints from './base/breakpoints';
// import typography from './base/typography';
import boxShadows from './base/boxShadows';
import borders from './base/borders';

// Soft UI Dashboard React helper functions
import boxShadow from './functions/boxShadow';
import hexToRgb from './functions/hexToRgb';
import linearGradient from './functions/linearGradient';
import pxToRem from './functions/pxToRem';
import rgba from './functions/rgba';

export function ThemeUI(modeTheme) {
  const themeOptions = {
    breakpoints: { ...breakpoints },
    palette: {
      mode: modeTheme === MODE_THEME.DARK ? MODE_THEME.DARK : MODE_THEME.LIGHT,
      background: {
        default: modeTheme === MODE_THEME.LIGHT ? BACKGROUND_COLOR.default : BACKGROUND_COLOR.dark,
      },
      ...colors(modeTheme),
    },
    typography: {
      ...typography,
    },
    boxShadows: { ...boxShadows },
    borders: { ...borders },
    shadows,
    shape: { borderRadius: 8 },
    functions: {
      boxShadow,
      hexToRgb,
      linearGradient,
      pxToRem,
      rgba,
    },
  };

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return theme;
}
