import { API } from '../config';

export const saveGradeService = async (data) => {
  const res = await API.post('grades/add', data);
  return await res.data;
};

export const updateGradeService = async (id, data) => {
  const res = await API.put(`grades/edit/${id}`, data);
  return await res.data;
};

export const deleteGradeService = async (id) => {
  const res = await API.delete(`grades/delete/${id}`);
  return await res.data;
};

export const getGradesService = async () => {
  const res = await API.get('grades/list');
  return await res.data;
};

export const getDetailGradeService = async (id) => {
  const res = await API.get(`grades/detail/${id}`);
  return await res.data;
};
