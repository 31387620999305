/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React Base Styles
import { TRANSPARENT } from '../base/colors';

const iconButton = () => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: TRANSPARENT.main,
        },
      },
    },
  },
});

export default iconButton;
