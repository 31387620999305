export const SELECTED_ACTION = {
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UPLOAD_DELIVERABLE: 'UPLOAD_DELIVERABLE',
};

export const MODE = {
  DEV: 'dev',
  PROD: 'production',
  STAGING: 'staging',
};

export const ROLE_PROFILE = {
  ADMIN: 'Admin',
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
};

export const GENDER = {
  FEMALE: 'female',
  MALE: 'male',
  OTHER: 'other',
};

export const ACTION_COMPONENT = {
  UPLOAD_ATTENDANCE: 'upload-attendance',
  QUERY_ATTENDANCE: 'query-attendance',
};

export const STATUS_ALERT = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MODULE = {
  TRACE: 'trace',
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  LOG: 'log',
};

export const TYPE_ICONS = {
  HOME_ICON: 'home_icon',
  MENU_ICON: 'menu_icon',
  SEARCH_ICON: 'search_icon',
  SETTING_ICON: 'setting_icon',
  ADD_ICON: 'add_icon',
  PLUS_ICON: 'plus_icon',
  TRASH_ICON: 'trash_icon',
  BOOK_ICON: 'book_icon',
  PENCIL_ICON: 'pencil_icon',
  LIST_ICON: 'list_icon',
  SEND_ICON: 'send_icon',
  CHECK_ICON: 'check_icon',
  CLOSE_ICON: 'close_icon',
};

export const TYPE_IMAGE_ICONS = {
  HOME_ICON: 'Home',
  ABC_ICON: 'ABC',
  COURSES_ICON: 'courses',
  SCORE_ICON: 'score',
  REPORT_CARD_ICON: 'reportCard',
  SHOP_ICON: 'shoppingCart',
  LOGOUT_ICON: 'logout',
  SETTINGS_ICON: 'settings',
  PROFILE_ICON: 'profile',
  MOON_ICON: 'moon',
  SUN_ICON: 'sun',
  NOTIFICATION_ICON: 'notification',
  EN_ICON: 'en',
  ES_ICON: 'es',
  ADVERTISEMENT_ICON: 'advertisement',
  ASSISTENCE_ICON: 'assistence',
};

export const MODE_THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const LOCALE = {
  EN: 'en',
  ES: 'es',
  EN_US: 'en-US',
};

export const ACTIONS_FORM = {
  NOT_CHANGED: 'not_changed',
  UPDATE: 'update',
};

export const COLOR = {
  SUCCESS: 'success',
  INHERIT: 'inherit',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const COLOR_ICON_BUTTON = {
  SUCCESS: 'success',
  PRIMARY: 'primary',
  INHERIT: 'inherit',
  DEFAULT: 'default',
  SECONDARY: 'secondary',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const TYPE_QUERY_EVAL = {
  REPORT_CARD_ID: 'By_Report_Card_Id',
  EVALUATION_ID: 'By_Evaluation_Id',
};

export const TYPE_ACTIVITY = {
  ASSIGNMENT: 'Assignment',
  MATERIAL: 'Material',
  TOPIC: 'Topic',
};

export const STATUS_DELIVERABLE = {
  DRAFT: 'draft',
  DELIVERED: 'delivered',
  PENDING: 'pending',
  QUALIFIED: 'qualified',
  RETARTED: 'retarded',
};

export const TYPE_COMMENT = {
  RECEPTOR: 'RECEPTOR',
  EMISOR: 'EMISOR',
};

export const POSITION_BUBBLE_COMMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};
