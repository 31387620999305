import { useState } from 'react';

export const UseHookModal = () => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [valuesModal, setValuesModal] = useState({
    openModal: { isShow: false },
    editModal: { isShow: false },
    uploadModal: { isShow: false },
  });

  const handleToggle = () => {
    setOpenModal(!isOpenModal);
  };

  function handleShowAdd(nameInput) {
    setValuesModal({ ...valuesModal, ...nameInput });
  }

  function handleShowEdit(nameInput) {
    setValuesModal({ ...valuesModal, ...nameInput });
  }

  function handleShowUpload(nameInput) {
    setValuesModal({ ...valuesModal, ...nameInput });
  }

  return {
    handleShowAdd,
    handleShowEdit,
    handleShowUpload,
    valuesModal,
    handleToggle,
    isOpenModal,
  };
};
