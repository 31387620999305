import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/index.css';
import './i18n';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { logging } from './utils/logger';
import { MODULE } from './utils/enums';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { setUpInterceptor } from './config';
import { NotificationsContextProvider } from './contexts/notifications';
import { SnackProvider } from './components/molecules/Snackbar';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <Provider store={store}>
        <BrowserRouter>
          <SnackProvider>
            <NotificationsContextProvider>
              <App />
            </NotificationsContextProvider>
          </SnackProvider>
        </BrowserRouter>
    </Provider>
  </HelmetProvider>,
);

logging
  .configure({
    minLevels: {
      '': 'info',
      core: 'warn',
    },
  })
  .registerConsoleLogger();

const logger = logging.getLogger(MODULE.INFO);

logger.info('!Im Ready');

setUpInterceptor(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
