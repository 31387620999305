/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import { LIGHT, WHITE, SLIDERCOLORS, BLACK, GRADIENTS } from '../base/colors';
import borders from '../base/borders';
import boxShadows from '../base/boxShadows';

// Soft UI Dashboard React helper functions
import linearGradient from '../functions/linearGradient';
import pxToRem from '../functions/pxToRem';

const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;

const slider = () => ({
  MuiSlider: {
    styleOverrides: {
      root: {
        width: '100%',

        '& .MuiSlider-active, & .Mui-focusVisible': {
          boxShadow: 'none !important',
        },

        '& .MuiSlider-valueLabel': {
          color: BLACK.main,
        },
      },

      rail: {
        height: pxToRem(3),
        backgroundColor: LIGHT.main,
        borderRadius: borderRadius.sm,
      },

      track: {
        backgroundImage: linearGradient(GRADIENTS.info.main, GRADIENTS.info.state),
        height: pxToRem(6),
        position: 'relative',
        top: pxToRem(2),
        border: 'none',
        borderRadius: borderRadius.lg,
        zIndex: 1,
      },

      thumb: {
        width: pxToRem(15),
        height: pxToRem(15),
        backgroundColor: WHITE.main,
        zIndex: 10,
        boxShadow: sliderBoxShadow.thumb,
        border: `${borderWidth[1]} solid ${SLIDERCOLORS.thumb.borderColor}`,

        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default slider;
