/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React Base Styles
import { TRANSPARENT, INFO, SECONDARY, GREY } from '../../base/colors';
import typography from '../../base/typography';

// Soft UI Dashboard React Helper Functions
import pxToRem from '../../functions/pxToRem';

const { size } = typography;

const buttonText = {
  base: {
    backgroundColor: TRANSPARENT.main,
    height: 'max-content',
    color: INFO.main,
    boxShadow: 'none',
    padding: `${pxToRem(6)} ${pxToRem(12)}`,

    '&:hover': {
      backgroundColor: TRANSPARENT.main,
      boxShadow: 'none',
      color: INFO.focus,
    },

    '&:focus': {
      boxShadow: 'none',
      color: INFO.focus,
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
      boxShadow: 'none',
    },

    '&:disabled': {
      color: GREY[600],
      boxShadow: 'none',
    },

    '& .material-icons, .material-icons-round, svg, span': {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    fontSize: size.xs,

    '& .material-icons, .material-icons-round, svg, span': {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    fontSize: size.sm,

    '& .material-icons, .material-icons-round, svg, span': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    color: INFO.main,
    backgroundColor: TRANSPARENT.main,

    '&:hover': {
      color: INFO.focus,
      backgroundColor: TRANSPARENT.main,
    },

    '&:focus:not(:hover)': {
      color: INFO.focus,
      backgroundColor: TRANSPARENT.focus,
      boxShadow: 'none',
    },
  },

  secondary: {
    color: SECONDARY.focus,
    backgroundColor: TRANSPARENT.main,

    '&:hover': {
      color: SECONDARY.focus,
      backgroundColor: TRANSPARENT.main,
    },

    '&:focus:not(:hover)': {
      color: SECONDARY.focus,
      backgroundColor: TRANSPARENT.focus,
      boxShadow: 'none',
    },
  },
};

export default buttonText;
