import { API } from '../config';

const signInClient = async (data) => {
  const res = await API.post('users/signIn', data);
  return await res.data;
};

const signUpClient = async (data) => {
  const res = await API.post('users/signUp', data);
  return await res.data;
};

const logoutClient = async () => {
  const res = await API.delete('users/logOut');
  return await res.data;
};

const getCsrfToken = async () => {
  const res = await API.get('users/getCSRFToken');
  return await res.data;
};

export function authServices() {
  return { signInClient, signUpClient, logoutClient, getCsrfToken };
}
