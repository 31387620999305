import { combineReducers } from 'redux';
import auth from './auth';
import alerts from './alert';
import profileSlice from './profile';
import periodSlice from './abc/periods';
import groupSlice from './abc/groups';
import gradeSlice from './abc/grades';
import subjectSlice from './abc/subjects';
import studentsSlice from './abc/students';
import teachersSlice from './abc/teachers';
import schoolsSlice from './abc/schools';
import adminsSlice from './abc/admins';
import parentsSlice from './abc/parents';
import courseSlice from './courses';
import activitiesSlice from './activities';
import deliverablesSlice from './deliverables';

const rootReducers = combineReducers({
  auth,
  alerts,
  profileSlice,
  periodSlice,
  groupSlice,
  gradeSlice,
  subjectSlice,
  studentsSlice,
  teachersSlice,
  schoolsSlice,
  adminsSlice,
  parentsSlice,
  courseSlice,
  activitiesSlice,
  deliverablesSlice,
});
export type RootState = ReturnType<typeof rootReducers>;
export default rootReducers;
