/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import typography from '../../base/typography';
import { GREY, DARK, SECONDARY } from '../../base/colors';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const { size, fontWeightRegular } = typography;

const stepLabel = () => ({
  MuiStepLabel: {
    styleOverrides: {
      label: {
        marginTop: `${pxToRem(8)} !important`,
        fontWeight: fontWeightRegular,
        fontSize: size.md,
        color: GREY[300],

        '&.Mui-active': {
          fontWeight: `${fontWeightRegular} !important`,
          color: `${DARK.main} !important`,
        },

        '&.Mui-completed': {
          fontWeight: `${fontWeightRegular} !important`,
          color: `${SECONDARY.main} !important`,
        },
      },
    },
  },
});

export default stepLabel;
