import { useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AssistancesContext, initialState } from './context';
import { ACTIONS, AssistancesContextReducer } from './reducers';

const AssistancesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AssistancesContextReducer, initialState);

  const setStudentsByCourse = useCallback((props) => {
    dispatch({
      type: ACTIONS.SET_STUDENTS_BY_COURSE,
      payload: {
        students: props,
      },
    });
  }, []);

  const setAssistanceId = useCallback((assistanceId) => {
    dispatch({
      type: ACTIONS.SET_ASSISTANCE_ID,
      payload: { assistanceId },
    });
  }, []);

  const setDetailAssistance = useCallback((selectedAssistance) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_ASSISTANCE,
      payload: { selectedAssistance },
    });
  }, []);

  const setStudentsAttendance = useCallback((props) => {
    dispatch({ type: ACTIONS.SET_STUDENTS_ATTENDANCE, payload: { studentsAttendance: props } });
  }, []);

  const setChildrenSelected = useCallback(
    (childrenSelected) =>
      dispatch({
        type: ACTIONS.SET_CHILDREN_SELECTED,
        payload: {
          childrenSelected,
        },
      }),
    [],
  );

  const setCourseSelected = useCallback(
    (courseSelected) =>
      dispatch({
        type: ACTIONS.SET_COURSE_SELECTED,
        payload: {
          courseSelected,
        },
      }),
    [],
  );

  const contextState = useMemo(
    () => ({
      ...state,
      setStudentsByCourse,
      setAssistanceId,
      setDetailAssistance,
      setStudentsAttendance,
      setChildrenSelected,
      setCourseSelected,
    }),
    [
      state,
      setStudentsByCourse,
      setAssistanceId,
      setDetailAssistance,
      setStudentsAttendance,
      setChildrenSelected,
      setCourseSelected,
    ],
  );

  return <AssistancesContext.Provider value={contextState}>{children}</AssistancesContext.Provider>;
};

AssistancesContextProvider.propTypes = {
  children: PropTypes.node,
};

export default AssistancesContextProvider;