import { createSlice } from '@reduxjs/toolkit';
import { createAlert } from '../../alert';
import {
  deleteUserService,
  saveUserService,
  serviceGetParent,
  serviceGetParents,
  updateUserService,
} from '../../../../services/users';

const initialState = {
  loadingFormParent: false,
  loadingParents: false,
  dataParents: [],
};

const parentsSlice = createSlice({
  name: 'parentsSlice',
  initialState,
  reducers: {
    begin: (state) => {
      state.loadingFormParent = true;
      state.loadingParents = true;
    },
    all: (state, action) => {
      state.loadingFormParent = false;
      state.loadingParents = false;
      state.dataParents = action.payload;
    },
    successInsert: (state, action) => {
      state.error = false;
      state.loadingFormParent = false;
      state.loadingParents = false;
      if (action.payload) {
        const Items = state.dataParents;
        Items.push(action.payload);
        state.dataParents = Items;
      }
    },
    successUpdate: (state, action) => {
      state.error = false;
      state.loadingFormParent = false;
      state.loadingParents = false;
      if (action.payload) {
        const updatedItem = action.payload;
        const items = state.dataParents.map((item) =>
          item._id === updatedItem._id ? updatedItem : item,
        );
        state.dataParents = items;
      }
    },
    successRemove: (state, action) => {
      state.dataParents = state.dataParents.filter((item) => item._id !== action.payload);
    },
    onSelectParent: (state, action) => {
      state.selectedIdParent = action.payload;
    },
    setDetailParent: (state, action) => {
      state.detailParent = action.payload;
    },
    failure: (state) => {
      state.error = true;
      state.loadingFormParent = false;
      state.loadingParents = false;
    },
    clearStateParent: (state) => {
      state.loadingFormParent = false;
      state.loadingParents = false;
      state.dataParents = [];
      state.selectedIdParent = null;
      state.detailParent = null;
    },
  },
});

export const getParents = () => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await serviceGetParents();
      dispatch(all(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const saveParent = (data, formikHelpers, handleClose) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await saveUserService(data);
      dispatch(createAlert({ message: response.message, status: 'success' }));
      dispatch(successInsert(response.data));
      formikHelpers.resetForm();
      handleClose();
    } catch (e) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const updatedParent = (id, data, handleClose, formikHelpers) => {
  return async (dispatch) => {
    dispatch(begin());
    try {
      const response = await updateUserService(id, data);

      if (response.changes) {
        const responseDetail = await serviceGetParent(id);
        dispatch(successUpdate(responseDetail));
      }
      dispatch(successUpdate());
      dispatch(createAlert({ message: response.message, status: 'success' }));
      handleClose();
    } catch (error) {
      dispatch(failure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
};

export const deleteParent = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteUserService(id);
      dispatch(successRemove(id));
      dispatch(createAlert({ message: response.message, status: 'success' }));
    } catch (error) {
      dispatch(failure());
    }
  };
};

export const getDetailParent = (id) => {
  return async (dispatch) => {
    try {
      const response = await serviceGetParent(id);
      dispatch(setDetailParent(response));
    } catch (e) {
      dispatch(failure());
    }
  };
};

export const {
  begin,
  all,
  failure,
  successInsert,
  onSelectParent,
  setDetailParent,
  successUpdate,
  successRemove,
  clearStateParent,
} = parentsSlice.actions;

export default parentsSlice.reducer;
