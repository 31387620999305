/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import boxShadows from '../../base/boxShadows';
import typography from '../../base/typography';
import colors, { WHITE, TRANSPARENT, LIGHT, DARK, GRADIENTS } from '../../base/colors';
import borders from '../../base/borders';

// Soft UI Dashboard React helper functions
import pxToRem from '../../functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { text } = colors();
const { borderRadius } = borders;

const autocomplete = () => ({
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        boxShadow: lg,
        padding: pxToRem(8),
        fontSize: size.sm,
        color: text.main,
        textAlign: 'left',
        backgroundColor: `${WHITE.main} !important`,
        borderRadius: borderRadius.md,
      },

      paper: {
        boxShadow: 'none',
        backgroundColor: TRANSPARENT.main,
      },

      option: {
        padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        fontSize: size.sm,
        color: text.main,
        transition: 'background-color 300ms ease, color 300ms ease',

        '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
          backgroundColor: LIGHT.main,
          color: DARK.main,
        },

        '&[aria-selected="true"]': {
          backgroundColor: `${LIGHT.main} !important`,
          color: `${DARK.main} !important`,
        },
      },

      noOptions: {
        fontSize: size.sm,
        color: text.main,
      },

      groupLabel: {
        color: DARK.main,
      },

      loading: {
        fontSize: size.sm,
        color: text.main,
      },

      tag: {
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        padding: pxToRem(4),
        backgroundColor: GRADIENTS.dark.state,
        color: WHITE.main,

        '& .MuiChip-label': {
          lineHeight: 1.2,
          padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
        },

        '& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus': {
          color: WHITE.main,
          marginRight: 0,
        },
      },
    },
  },
});

export default autocomplete;
