/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React Base Styles
import { DARK, WHITE, GREY, INPUT_COLORS } from '../../base/colors';
import typography from '../../base/typography';
import borders from '../../base/borders';

// Soft UI Dashboard PRO helper functions
import pxToRem from '../../functions/pxToRem';

const { size, fontWeightRegular } = typography;
const { borderWidth, borderRadius } = borders;

const inputBase = () => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        display: 'grid !important',
        placeItems: 'center !important',
        width: '100% !important',
        height: 'auto !important',
        padding: `${pxToRem(8)} ${pxToRem(12)}`,
        fontSize: `${size.sm} !important`,
        fontWeight: `${fontWeightRegular} !important`,
        lineHeight: '1.4 !important',
        color: `${GREY[700]} !important`,
        backgroundColor: `${WHITE.main} !important`,
        backgroundClip: 'padding-box !important',
        border: `${borderWidth[1]} solid ${INPUT_COLORS.borderColor.main}`,
        appearance: 'none !important',
        borderRadius: borderRadius.md,
        transition: 'box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important',
      },

      input: {
        width: '100% !important',
        height: `${pxToRem(22)}`,
        padding: '0 !important',

        '&::-webkit-input-placeholder': {
          color: `${DARK.main} !important`,
        },
      },
    },
  },
});

export default inputBase;
