import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { initialState, NotificationsContext, SetNotificationsType } from './context';
import { ACTIONS, NotiicationsContextReducer } from './reducers';

interface NotificationsContextProviderInterface {
  children: ReactNode;
}

export const NotificationsContextProvider = ({
  children,
}: NotificationsContextProviderInterface) => {
  const [state, dispatch] = useReducer(NotiicationsContextReducer, initialState);

  const setNotifications = useCallback(({ ...props }: SetNotificationsType) => {
    dispatch({
      type: ACTIONS.SET_NOTIFICATIONS,
      payload: {
        notifications: { ...props },
      },
    });
  }, []);

  const setNotificationsPagination = useCallback(({ ...props }: SetNotificationsType) => {
    dispatch({
      type: ACTIONS.SET_NOTIFICATIONS_PAGINATION,
      payload: {
        notifications: { ...props },
      },
    });
  }, []);

  const contextState = useMemo(
    () => ({ ...state, setNotifications, setNotificationsPagination }),
    [state, setNotifications, setNotificationsPagination],
  );

  return (
    <NotificationsContext.Provider value={contextState}>{children}</NotificationsContext.Provider>
  );
};
