import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { ResponseNotificationsType } from '../../utils/types';

export type SetNotificationsType = ResponseNotificationsType & {
  isLoading: boolean;
};

export interface NotificationsContextInterface {
  notifications: SetNotificationsType;
  setNotifications: (props: SetNotificationsType) => void;
  setNotificationsPagination: (props: SetNotificationsType) => void;
}

export const initialState: NotificationsContextInterface = {
  notifications: {
    currentPage: 1,
    totalPages: 0,
    isLoading: false,
    data: [],
  },
  setNotifications: noop,
  setNotificationsPagination: noop,
};

export const NotificationsContext = createContext<NotificationsContextInterface>(initialState);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  return context;
};
